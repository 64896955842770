.appSidebar {
    position: absolute;
    z-index: 11;
    padding: 30px;
    max-height: 100vh;
    overflow-y: scroll;
    padding-right: 0px;
}

#appLogoHover {
    display: none;
}

.appSidebar:hover #appLogoHover {
    display: block;
}

.appSidebar:hover {
    padding-right: 0px;
    background-color: #7363d6;
    border-radius: 0 24px 24px 0;
    font-weight: 500;
}

.appSidebar:hover #logodiv {
    display: none;
}

.activeSidebarMenu {
    background: white;
    color: #7363d6;
}

.inActiveSidebarMenu {
    background-color: #7363d6;
    color: #fff;
}

.bgWhite {
    background: #fff;
}

.bgPurple {
    background: #7363d6;
}

.sidebarPadding {
    padding: 16px;
    border-radius: 30px 0 0 30px;
}

.bgForLogo {
    background: #ecf0fc;
    padding: 10px 15px;
    border-radius: 8px;
    margin-right: 30px;
    margin-bottom: 24px;
}

.closedSidebar .sidebarPadding {
    /* padding: ; */
    border-radius: 50px;
    /* background-color: #7363D6; */
    max-height: 100vh;
    justify-content: center;
}

.appBackground {
    background-image: linear-gradient(to bottom, #4169e11a, #4169e114);
    min-height: 100vh;
    max-height: 100%;
    /* width: max-content; */
}

.closedSidebarLogo {
    /* padding: 22px 14px; */
    width: 74px;
    height: 74px;
    border-radius: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.activeSidebarClosedBg {
    background: #7363d6;
}

.app-main-custom {
    padding-top: 98px;
}

.app-main-outer-custom {
    min-height: calc(100vh - 98px);
    max-height: calc(100vh - 98px);
    overflow-y: auto;
}

.app-main__inner-custom {
    padding-right: 30px;
}

.appSidebarOverlayForMobile {
    display: none;
}

@media screen and (min-width: 1440px) {
    .appBackground {
        display: flex;
        justify-content: center;
    }

    .appContainerMaxWidth,
    .containerDiv,
    .customHeader {
        max-width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .appSidebarCont {
        display: none;
    }
    .appSidebarCont.inActive {
        display: block;
    }
    .appSidebar.appSidebarMobile {
        padding-right: 0px;
        background-color: #7363d6;
        border-radius: 0 24px 24px 0;
        font-weight: 500;
    }
    .appSidebar #appLogoHover {
        display: block;
    }
    .appSidebar #logodiv {
        display: none;
    }
    .appSidebarOverlayForMobile {
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.8;
        z-index: 11;
    }
}
