.customScroll::-webkit-scrollbar {
    height: 2px;
    width: 5px;
    background: #67676757;
    border-radius: 10px;
}

.customScroll::-webkit-scrollbar-track {
    border-radius: 10px;
}

.customScroll::-webkit-scrollbar-thumb {
    background: #676767;
    border-radius: 10px;
}
.createPromotionFormCard{
    padding: 24px;
    border-radius: 24px;
}
.createPromotionMainFormCont{
    height: calc(100vh - 320px);
    overflow-y: auto;
}
.createPromotionMainFormCont .customeRow{
    display: grid;
    grid-template-columns: repeat(3, calc(33% - 14px));
    gap: 24px;
    padding-bottom: 20px;
}

.createPromotionMainFormCont .customTextField .MuiInputBase-root.MuiFilledInput-root{
    padding-right: 0px;
}

.createPromotionMainFormCont .customTextField .MuiInputBase-input.MuiFilledInput-input.Mui-disabled{
    background: rgba(0, 0, 0, 0.12);
}

.promotionTypeValueCustomeRow{
    display: grid;
    grid-template-columns: 100%;
    gap: 24px;
    padding-bottom: 40px;
}

.promotionTypeValue{
    min-height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    background-color: #7363D6;
    color: #fff;
    border-radius: 8px;
}