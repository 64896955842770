.table-container {
    padding: 16px;
    border-radius: 10px;
    font-family:
        Noto Sans,
        sans-serif;
    width: auto;
}

.header {
    font-size: 14px;
    font-family:
        Noto Sans,
        sans-serif;
    display: flex;
    justify-content: space-between;
    color: #000;
    padding-left: 8px;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
}

#first-th {
    border: none;
}

.custom-table th,
.custom-table td {
    border: 1.5px dashed #d6d6d6;
    padding: 8px 12px;
    text-align: center;
}

.custom-table th,
#value-header {
    color: #676767;
    font-size: 12px;
    font-weight: normal;
}

.custom-table td {
    font-weight: 500;
    color: #000;
}
