.ruleMangmentViewCont {}

.ruleMangmentViewTableCont {
    width: 595px;
    padding-right: 10px;
    height: calc(100vh - 250px);
}

.ruleMangmentViewTableCont.reports .customTable {
    height: 100% !important;
}

.ruleMangmentViewListCont {
    width: calc(100% - 595px);
    padding-left: 10px;
    height: calc(100vh - 250px);
}

.ruleMangmentViewTableCont table {
    width: 100%;
    border: 1px solid;
    border-radius: 10px;
}

.ruleMangmentViewTableCont table tr th {
    border: 1px solid;
    padding: 10px;
}

.ruleMangmentViewTableCont table tr td {
    border: 1px solid;
    padding: 10px;
    cursor: pointer;
}

.ruleMangmentViewTableCont table tr.active td {
    background-color: #e74c3c33;
}

.ruleMangmentViewTableCont .tableCustomeHeightClass .ag-root {
    width: 100%;
}

.excelHeaderCont {
    width: 100%;
    border: 1px solid;
    border-radius: 10px;
    padding: 10px;
    padding-right: 0px;
    background-color: #fff;
}

.excelCorrectHeaderMapingCont {
    width: 100%;
    height: calc(50% - 10px);
    display: flex;
    gap: 20px;
}

.excelCorrectHeaderMapingCont:last-child{
    margin-top: 20px;
}

.excelHeaderMapingCont {
    width: calc(50% - 10px);
    height: 100%;
    margin-right: 10px;
    border: 1px solid;
    border-radius: 10px;
    overflow-y: auto;
    padding: 10px;
    padding-right: 0px;
    background-color: #fff;
}

.correctHeaderMapCont {
    width: calc(50% - 10px);
    height: 100%;
    border: 1px solid;
    margin-left: 10px;
    border-radius: 10px;
    overflow-y: auto;
    padding: 10px;
    padding-right: 0px;
    background-color: #fff;
}

.ruleMangmentForm {
    height: calc(100vh - 310px);
}

/* .ruleMangmentForm.updateCont {
    height: calc(100vh - 292px);
} */

.leftTypeBox {
    flex: 0.4;
    padding-right: 10px;
}

.rightTypeBox {
    flex: 0.6;
    padding-left: 10px;
}

/* .ruleMangmentForm.createForm .leftTypeBox {
    flex: 0.4;
}
.ruleMangmentForm.createForm .rightTypeBox{
    flex: 0.6;
} */

.calcH75{
    height: calc(100% - 75px);
}

.calcH50{
    height: calc(100% - 50px);
}

.calcH58{
    height: calc(100% - 58px);
    max-height: calc(100% - 58px);;
}

.calcH36{
    height: calc(100% - 36px);
}

.calcH36_58{
    height: calc(100% - 58px);
}

.CH50{
    height: calc(50% - 5px);
}

.minHeight58{
    min-height: 58px;
}

#headers {
    gap: 20px;
}

.width-100{
    width: 100%;
}

.height100 {
    height: 100%;
}

.height50 {
    height: 50%;
}

.textarea {
    resize: none;
    padding: 10px;
    border-radius: 10px;
}

.textarea:focus {
    outline: none !important;
    border-color: inherit;
}

.getExcelDataUl{
    padding: 0px;
    margin: 0px;
    list-style: none;
}

.getExcelDataUl.popupCont{
    height: calc(100vh - 420px);
    overflow-y: auto;
}

.getExcelDataUl li{
    cursor: pointer;
    margin-bottom: 5px;
}

.textOverlowAuto.mappedExcelHeadersDragableCont{
    overflow-y: auto;
    height: calc(100vh - 368px);
    position: relative;
}

.DragableItemsContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 3px;
    margin-bottom: 4px;
    border-bottom: 1px solid #000;
    max-height: 28px;
    min-height: 28px;
}

.ItemsContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #000;
}

.overFlowYScroll{
    overflow-y: auto;
}

.customCreateButton.disabled {
    pointer-events: none;
    /* cursor: not-allowed; */
    opacity: 0.6;
}


/* info ICon Start */
.info-icon-container {
    position: relative;
    display: inline-block;
}

.info-icon {
    background-color: #4169E1;
    color: white;
    border-radius: 50%;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
}

.tooltip {
    visibility: hidden;
    width: 400px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 35px;
    right: 0;
    margin-left: -75px;
    /* Use margin to center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 95%;
    margin-left: 0px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.info-icon-container:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

/* info icon end */

.border10 {
    border: 10px;
}

.height50per {
    height: calc(50% - 10px);
}

.overflowAuto {
    height: 100%;
    overflow-y: auto;
    padding-right: 10px;
}

.textOverlowAuto {
    width: 100%;
    border: 1px solid;
    border-radius: 10px;
    overflow-y: hidden;
    background-color: #fff;
    /* padding: 10px; */
}

.textOverlowAuto .textarea {
    width: 100%;
    border: none;
}

.customeRuleHeaderSlectedModal .subCustomeRuleHeaderSlectedModal{
    max-width: 400px;
}

.disabled-item {
    pointer-events: none;
    opacity: 0.5;
}

.leftTypeBox .customCreateButton{
    width: initial;
}

.openListCont {
    /* width: 300px; */
    /* height: 300px; */
    background-color: #fff;
    border-radius: 5px;
    z-index: 9999;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    width: 200px;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
  }

.closeCustomePopup{
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.labelHeader{
    font-weight: 600;
}

@media(max-width: 767px){
    .w-50{
        width: 100% !important;
    }
    .ruleMangTabHeader{
        flex-direction: column;
    }
    .ruleMangTabHeader .rulemangFilterCont{
        margin-left: 0px !important;
        margin-right: 0px !important;
        margin-bottom: 10px;
    }
    .rulemangementSearch.search-wrapper.active{
        position: relative;
        background-color: transparent;
        justify-content: flex-start;
    }
    .ruleMangmentViewCont{
        flex-direction: column;
        padding-bottom: 100px;
    }
    .ruleMangmentViewTableCont{
        width: 100%;
        /* height: 100%; */
        margin-bottom: 20px;
    }
    .rulemangCreateUpdate{
        flex-wrap: wrap;
    }
    .rulemangCreateUpdate .position-relative:last-child{
        margin-top: 15px;
    }
    .ruleMangmentViewListCont{
        width: 100%;
        padding-left: 0px;
        height: initial;
    }
    .ruleMangmentViewListCont #headers,
    .excelCorrectHeaderMapingCont{
        flex-direction: column;
    }
    .excelHeaderCont{
        min-height: 200px;
    }
    .excelHeaderMapingCont,
    .correctHeaderMapCont{
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
        margin-bottom: 20px;
    }
    .ruleMangmentForm{
        display: flex;
        flex-direction: column;
        height: calc(100vh - 220px);
    }
    .h75_dflex{
        flex-direction: column;
    }
    .w-50.pe-3{
        padding-right: 0px !important;
    }
    .ruleMangmentForm.updateCont{
        width: 100%;
        flex-direction: column;
        height: 100vh;
    }
    .leftTypeBox{
        width: 100%;
        padding: 0px;
    }
    .rightTypeBox{
        width: 100%;
        padding: 0px;
        padding-top: 20px;
    }
    .height50{
        padding-bottom: 20px;
        flex-direction: column;
        height: initial;
    }
    .height50 .w-50{
        width: 100% !important;
    }
    .height50 .w-50 .h-75{
        height: initial !important;
    }
}