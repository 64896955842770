.ag-header, .ag-header-cell, .ag-header-cell-label {
    background-color: #dae1f9;
}

.ag-header-pinned-left .ag-header-cell, 
.ag-header-pinned-right .ag-header-cell {
    background-color: #dae1f9;
}

.ag-header-cell-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    line-height: 1.2;
    max-width: 100%;
}

.ag-cell {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    line-height: 1.2;
    max-width: 100%;
}

.ag-horizontal-left-spacer {
    opacity: 0 !important;
}

.ag-horizontal-right-spacer {
    opacity: 0 !important;
}