.cardBrandManagementContainer {
    display: grid;
    grid-template-columns: 14.8% 14.8% 14.8% 14.8% 14.8% 14.8%;
    gap: 32px;
    padding-bottom: 90px;
}

.brandUserHeading p {
    margin: 0px;
    font:
        normal normal 300 30px/40px Montserrat,
        sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.eachBrandCard {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 24px;
    padding: 15px;
    position: relative;
    box-shadow: 0px 0px 0px 0px #6767674a;
    cursor: pointer;
}

.eachBrandCard:hover {
    box-shadow: 15px 15px 20px #0000001a;
}

.redirectIconCont {
    background: #f2f2f2 0% 0% no-repeat padding-box;
    border: 1px solid #f2f2f2;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 15px;
}

.redirectIconCont img {
    padding: 9px 9px 12px 12px;
}

.redirectIconCont button {
    padding: 0;
    border: 1px solid #f2f2f2;
    border-radius: 25px;
}

.brandLogo {
    /* padding-top: 10px; */
}

.brandLogo div {
    padding: 8px;
    border: 1px solid #f8f8f8;
    border-radius: 12px;
    width: fit-content;
}

.brandDicriptionDetails {
    padding-top: 20px;
}

.brandName {
    font:
        normal normal 300 18px/22px Montserrat,
        sans-serif;
    letter-spacing: 0px;
    color: #161138;
    opacity: 1;
    margin: 0px;
    min-height: 45px;
}

.brandTotalStoreCount {
    font:
        normal normal 600 24px/33px Noto Sans,
        sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin: 0px;
}

.brandTotalStore {
    font:
        normal normal 300 12px/15px Montserrat,
        sans-serif;
    letter-spacing: 0px;
    color: #161138;
    opacity: 1;
    margin: 0px;
}

.dateRangeFilter {
    padding: 7px 16px;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    background-color: #fff;
    border-radius: 19px;
    border: none;
    line-height: 1;
    min-width: 230px;
}

.cardsContainer {
    display: grid;
    margin-top: 7px;
    grid-template-columns: 49% 49%;
    gap: 30px;
}

.cardBody {
    border-radius: 24px;
    border: 1px solid #f2f2f2;
    padding: 24px;
    background-color: #fff;
}

.cardBody:hover {
    box-shadow: 15px 15px 20px #0000001a;
}

.cardBody:first-child {
    /* background-color: #7263D6; */
    border-color: #7263d6;
    background-image: url("../../assets/png/purplePatternBg.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.cardBody.cardBodyCustomContForOnlyGreen:first-child {
    border-color: transparent;
    background-color: transparent;
}

.cardBody.cardBodyCustomContForOnlyGreen .cardBgGreenCustomCont {
    max-width: 100%;
    /* overflow-y: auto;
    padding-bottom: 3px; */
}

.cardBody.cardBodyCustomContForOnlyGreen .cardBgGreenCustomCont .regionBox {
    padding: 12px;
    max-width: 125px;
    min-width: initial;
    width: 100%;
}

.cardBody.cardBodyCustomContForOnlyGreen .IncreaseCustomCont {
    max-width: 100%;
    /* overflow-y: auto;
    padding-bottom: 3px; */
}

.cardBody.cardBodyCustomContForOnlyGreen .IncreaseCustomCont .regionBox {
    padding: 12px;
    max-width: 125px;
    min-width: initial;
    width: 100%;
}

.cardBody:first-child .imgDiv {
    background-color: white;
    padding: 10px;
}

.cardBody .imgDiv,
.modalBody .imgDiv {
    background-color: #f2f2f2;
    padding: 10px;
    width: fit-content;
    border-radius: 40px;
}

.cardBody .imgDiv img,
.modalBody .imgDiv img {
    width: 20px;
    height: 20px;
}

.mainContainer {
    /* padding-left: 10px; */
}

.cardHeadingDiv {
    display: flex;
    font-family: "Montserrat", sans-serif;
    align-items: center;
}

.cardHeadingDiv p {
    font-size: 18px;
    padding-left: 10px;
}

#rotateArrow {
    transform: rotate(180deg);
}

#top5States {
    background-color: #f5fffa;
}

#top5States {
    padding: 12px 16px;
}

#top5States {
    display: flex;
    justify-content: space-between;
    font-family: "Noto Sans", sans-serif;
    border-radius: 12px;
    gap: 10px;
}

#top5States div {
    border-right: 1px dashed #d6d6d6;
    max-width: 120px;
}

#top5States div p:nth-child(2) {
    font-size: 18px;
    font-weight: 600;
    flex-wrap: nowrap;
    display: flex;
    align-items: baseline;
}

#top5States div p:nth-child(2) span {
    font-size: 14px;
    padding-left: 5px;
}

#top5States div p:first-child {
    font-size: 14px;
    color: #333333;
}

#top5States div p:last-child {
    font-size: 12px;
    color: #676767;
}

#top5States div:first-child {
    padding-left: 0;
}

#top5States div:last-child {
    padding-right: 0;
    border-right: none;
}

#top5States div {
    padding-right: 19px;
}

.doorSales div {
    padding-right: 22.5px;
}

.doorSales div {
    width: 23%;
}

.doorSales {
    justify-content: space-between !important;
}

.bottom5 {
    background-color: #fff5f5 !important;
}

.categoryDivision {
    background-color: #ffffff !important;
}

#top5States.categoryDivision {
    padding: 12px 0 !important;
}

#storeCountLineHeight {
    line-height: 1.4;
}

.storeAndRevenue {
    background-color: #fff;
    padding: 3px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 1;
}

.storeAndRevenue .tab {
    padding: 10.5px 24px;
    cursor: pointer;
    border-radius: 16px;
}

.tabBackground {
    background-color: #d0d5ea;
}

.regionBox {
    border-radius: 12px;
    padding: 12px 16px;
    min-width: 120px;
}

.categoryPresenceDataHeading {
    display: grid;
    grid-template-columns: 50% 25% 25%;
}

.categoryPresenceDataHeading div {
    padding: 0 30px;
}

.categoryPresenceDataHeading div:first-child {
    padding-left: 15px;
}

.categoryPresenceDataHeading div:last-child {
    padding-right: 0;
    border: none;
}

.categoryPresenceData {
    display: grid;
    grid-template-columns: 50% 25% 25%;
    background-color: #fafafa;
    border-radius: 12px;
    margin-top: 8px;
}

.categoryPresenceData div {
    padding: 14px 30px 14px 30px;
    border-right: 1px dashed #d6d6d6;
    line-height: 1.2;
}

.categoryPresenceData div:first-child {
    padding-left: 15px;
}

.categoryPresenceData div:last-child {
    padding-right: 0;
    border: none;
}

.stateBusinessDataHeading {
    display: grid;
    grid-template-columns: 31% 23% 23% 23%;
    justify-content: space-between;
}

.stateBusinessDataHeading div {
    padding: 0 30px;
    text-wrap: nowrap;
}

.stateBusinessDataHeading div:first-child {
    padding-left: 15px;
}

.stateBusinessDataHeading div:last-child {
    padding-right: 0;
    border: none;
}

.stateBusinessData {
    display: grid;
    grid-template-columns: 31% 23% 23% 23%;
    background-color: #fafafa;
    border-radius: 12px;
    margin-top: 8px;
}

.stateBusinessData div {
    padding: 14px 30px 14px 30px;
    border-right: 1px dashed #d6d6d6;
    line-height: 1;
}

.stateBusinessData div:first-child {
    padding-left: 15px;
}

.stateBusinessData div:last-child {
    padding-right: 0;
    border: none;
}

#viewAllBtn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.brandFilter {
    max-width: 100px !important;
}

.categoryDivisionBg {
    background-image: url("../../assets/svg/leafUp.svg"), url("../../assets/svg/leafDown.svg");
    background-repeat: no-repeat;
    background-position:
        bottom left,
        top right;
}

.customPagination {
    position: absolute;
    bottom: 0;
    /* right: 40px; */
    z-index: 9;
    /* background-color: #fff; */
    left: 0;
    right: 0;
    padding-top: 20px;
    padding-left: 40px;
    padding-right: 40px;
}

#storeName {
    width: 85%;
}

.dataInsideModal {
    height: 400px;
    overflow-y: auto;
    padding-right: 10px;
}

.fontSizeCustom {
    font-size: 18px;
}

.imgCustomWidth {
    width: 12px;
}

.top-stores {
    min-width: 150px;
}

.grayDotClass {
    height: 12px;
    width: 12px;
    border-radius: 42px;
    padding: 6px !important;
    background-color: #d6d6d6;
    margin: 3px 6px 0 0;
}

.updateMaster {
    float: right;
    width: max-content;
}

.item-master .ag-header-cell-text {
    white-space: break-spaces;
}

@media (min-width: 991px) and (max-width: 1200px) {
    .cardBody.cardBodyCustomContForOnlyGreen .cardBgGreenCustomCont,
    .cardBody.cardBodyCustomContForOnlyGreen .IncreaseCustomCont {
        gap: 0.5rem !important;
        margin-top: 30px;
    }

    .cardBody.cardBodyCustomContForOnlyGreen .cardBgGreenCustomCont .regionBox,
    .cardBody.cardBodyCustomContForOnlyGreen .IncreaseCustomCont .regionBox {
        padding: 5px;
    }

    .textColorLighterGray {
        /* min-height: 36px; */
    }

    .minWidthCustom {
        min-width: 52px;
    }

    .minWidthCustom .imgCustomWidth {
        width: 10px;
    }

    .minWidthCustom.fontSize14 {
        font-size: 12px;
    }

    .fontSizeCustom {
        font-size: 16px;
    }

    .fontSizeSubCustom {
        font-size: 12px;
    }
}
@media (min-width: 767px) and (max-width: 1400px) {
    .display-height {
        max-height: calc(100vh - 225px);
        overflow-y: auto;
        grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    }
    .cardBrandManagementContainer {
        gap: 24px;
    }
    .top-stores {
        min-width: 110px;
    }
    #top5States div {
        border-right: 1px dashed #d6d6d6;
        max-width: 90px;
    }
}

@media (max-width: 767px) {
    .customPagination {
        padding-left: 10px;
        padding-right: 10px;
        background-color: #fff;
    }
    .updateMaster {
        margin-right: 38px;
    }
}
