/* src/BackIcon.css */
.back-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #e0e7ff;
    color: #6b7eff;
    font-size: 12px;
    margin: 5px 10px;
    cursor: pointer;
}
  