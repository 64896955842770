.customMultiSelect .MuiFormLabel-root.MuiInputLabel-root,
.customMultiSelect .MuiInputBase-input.MuiFilledInput-input.MuiSelect-select {
    font-family: Noto Sans, sans-serif;
    font-size: 14px;
    color: #676767;
}

.customMultiSelect .MuiFormControl-root {
    width: 100%;
}

.customMultiSelect .MuiInputBase-root.MuiFilledInput-root {
    background-color: #FFF;
    border: 1px solid #D6D6D6 !important;
    border-radius: 8px;
}

.customSelect .MuiTypography-root {
    font-family: Noto Sans, sans-serif;
    font-size: 14px;
    color: #676767;
}

.customSelect .MuiSvgIcon-root {
    width: 18px;
}

.customMultiSelect .MuiSelect-select.MuiInputBase-input.MuiFilledInput-input.MuiSelect-select {
    padding-top: 20.1px;
}

.customSelect.MuiButtonBase-root.MuiMenuItem-root {
    padding: 0 10px;
}

.customMultiSelect .MuiInputBase-input.MuiFilledInput-input.MuiSelect-select {
    font-family: Noto Sans, sans-serif;
    font-size: 14px;
    color: #000000;
    font-weight: 600;
}