.paginationBorder {
    border: 1px solid #D6D6D6;
    padding: 10px 18px;
}

.nxtBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    border: 1px solid #D6D6D6;
    border-radius: 0 19px 19px 0;
    line-height: 1;
    background-color: #fff;
}

.prevBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    border-radius: 19px 0 0 19px;
    border: 1px solid #D6D6D6;
    line-height: 1;
    background-color: #fff;
}

.activePage {
    background-color: #4169E1;
    color: #FFFFFF;
}