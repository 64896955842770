.backGround {
    /* background-image: url("../../../assets/svg/loginPagePhoto.svg"); */
    /* width: 50%; */
    background-repeat: no-repeat;
    background-color: #4169E1;
    background-image: url('../../../assets/svg/loginLeftBg.svg');
}

.authLayout #leftContainer {
    padding-top: 70px;
}

.formSide {
    background: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-image: url('../../../assets/svg/loginRightBg.svg');
    background-attachment: fixed;
    background-position: right;
}

.loginForm {
    padding: 45px;
    border: 1px solid #F2F2F2;
    border-radius: 24px;
    background: #FCFCFC 0% 0% no-repeat padding-box;
    color: #000000;
}

.loginForm .loginCard {
    min-width: 312px;
}

.authLayout {
    /* width: ; */
    /* background-image: url('../../../assets/svg/bgPatternImg.svg'); */
    /* background-repeat: no-repeat; */
    display: grid;
    grid-template-columns: 50% 50%;
    height: 100vh;
    overflow: hidden;
}

.pwdViewBtn {
    top: 27px;
    right: 12px;
    z-index: 10px;
    cursor: pointer;
    padding: 2px;
    background-color: white;
}

.maxWidthForOtpInfo {
    max-width: 278px;
}

.resendOtpBtn {
    border: none;
    background-color: white;
}

.opacityHalf {
    opacity: 50%;
}

.cursorNotAllowed {
    cursor: not-allowed;
}

.customShadow {
    box-shadow: 15px 15px 20px #00000024;
}

.forceLogoutCloseIconPlacement {
    position: absolute;
    top: 8px;
    right: 8px;
}

@media screen and (min-width:1200px) {
    .loginForm {
        min-width: 402px;
    }
}

@media screen and (max-width:1024px) {
    .backGround {
        display: none !important;
    }

    .authLayout {
        grid-template-columns: auto;
    }
}

@media screen and (max-width:425px) {
    .loginForm {
        padding: 20px;
        width: 100%;
    }

    .loginCard {
        min-width: auto !important;
    }
}