.history-container {
    padding: 20px;
    height: calc(100vh - 205px);
    border-radius: 16px;
    background: #fffefb 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #d6d6d666;
    overflow: auto;
}

.incentive-tab-btn {
    font-size: 12px;
    font-family:
        Noto Sans,
        sans-serif;
    padding: 10px 24px;
}

.active-incentive-btn {
    background: #e6e6e6;
    border-radius: 18px;
    font-weight: 600;
}
.wow-bill-history-data {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    gap: 23px;
}

.wow-bill {
    padding: 24px;
    background-color: #f5f5f5;
    border-radius: 12px;
    display: grid;
    grid-template-columns: 40% 50%;
    gap: 10px;
}

.incentive-details {
    display: flex;
    gap: 20px;
}

.incentive-view {
    width: 40%;
    height: fit-content;
    display: grid;
    grid-template-columns: 40% 50%;
    gap: 10px;
}

.bills {
    width: 60%;
    display: grid;
    grid-template-columns: auto auto;
    gap: 24px;
}

.store-name {
    max-height: 200px;
    overflow: auto;
}

.bill-details {
    background-color: #f4f4f4;
    padding: 24px;
    border: 1px solid #3333330d;
    border-radius: 12px;
    display: grid;
    grid-template-columns: 45% 50%;
    gap: 10px;
    height: fit-content;
}

@media screen and (max-width: 767px) {
    .wow-bill-history-data {
        display: block;
    }
    .wow-bill {
        margin-bottom: 20px;
    }
    .history-container {
        height: calc(100vh - 305px);
        padding: 12px;
    }
    .cat-season-btn-cont {
        margin: 0;
    }
    .tab-btn-bg {
        padding: 10px 15px;
    }
}
