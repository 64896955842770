.feedbackBtn {
    padding: 11px 40px;
    font-size: 12px;
    font-weight: 500;
}

.viewFeedbackCard {
    min-height: calc(100vh - 245px);
    padding: 24px 40px;
}

.contentContainer {
    max-height: calc(100vh - 330px);
    overflow: auto;
}

.btnContainer {
    gap: 16px;
    padding: 24px 40px;
    border-radius: 24px 24px 0 0;
    box-shadow: 12px 0 30px #0000001a;
}

.feedbackCancelBtn {
    padding: 15px 60px;
    color: #4169E1;
    font-weight: 500;
    font-size: 14px;
    border-radius: 30px;
}

.viewFeedackDetails {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    row-gap: 40px;
    font-family: 'Noto Sans', sans-serif;
}

#feedback-comment {
    grid-column: 2 / span 3;
}

#attached-images {
    display: grid;
    grid-template-columns: 120px 120px 120px 120px;
    gap: 16px;
}

#attached-images .storeValue {
    border: 1px solid #F2F2F2;
    border-radius: 12px;
}

.feedback-action {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    gap: 20px;
}

.feedback-action textarea {
    width: 100%;
    border: 1px solid #D6D6D6 !important;
    border-radius: 8px;
    padding: 10px;
    outline: none;
}

.feedback-form,
#form-field {
    min-width: 380px;
    gap: 20px;
    max-height: calc(100vh - 318px);
    overflow: auto;
}

.dropZoneContentFeedback {
    background-color: #FFFEFB;
    border: 1px solid #D6D6D6;
    border-radius: 8px;
    padding: 24px 310px;
    text-align: center;
}

.cardBorder {
    border: 1px solid #F2F2F2;
    box-shadow: 0px 3px 16px #D6D6D64D;
    border-radius: 24px;
}

.feedback-upload-btn {
    padding: 8px 35px;
    color: #4169E1;
    font-weight: 500;
    font-size: 14px;
    border-radius: 30px;
    width: max-content;
}

#the-count {
    float: right;
    font-size: 12px;
}

.feedback-remark textarea {
    resize: none;
}

.feedback {
    display: flex;
    gap: 16px;
}

.audio-player audio {
    background: #F2F2F2;
    border-radius: 37px;
}

.audio-player audio::-webkit-media-controls-play-button {
    color: red;
}

.uploadedFileDropZoneContent.dropZoneContentFeedback {
    padding: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
}

.imgPreview {
    border: 1px solid #F2F2F2;
    border-radius: 12px;
    gap: 16px;
    width: fit-content;
    padding: 10px;
    position: relative;
}

.img-preview-cont {
    display: grid;
    gap: 10px;
    grid-template-columns: 22% 22% 22% 22%;
    height: fit-content;
    width: 560px;
}

.remove-img svg {
    width: 9px;
    height: 9px;
}

.remove-img {
    width: fit-content;
    height: fit-content;
    position: absolute;
    top: 5px;
    right: 10px;
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: white;
    z-index: 99;
}

.view-feedback-cont {
    height: calc(100vh - 330px);
    overflow: auto;
}

.height_for_view_feedback {
    height: calc(100vh - 230px);
    overflow: auto;
}