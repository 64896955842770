.info-display {
    position: fixed;
    bottom: 40px;
    right: 0;
    padding: 20px 24px;
    color: white;
    z-index: 9;
    background-color: #504292;
    transition: width 0.3s, height 0.3s;
    cursor: pointer;
    border-radius: 10px 0 0 10px;
    min-height: 82px;
}

.full-view {
    width: 100%;
    max-width: 335px;
    height: auto;
    max-height: 82px;
}

.close-button {
    position: absolute;
    display: flex;
    align-items: center;
    top: 38%;
    left: -28px;
    padding: 7px;
    border-radius: 15px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 10;
    background-color: #504192;
}


@media (max-width: 768px) {
    .full-view.info-display{
        max-width: 300px;
        max-height: 100px;
        padding: 15px 15px;
    }
}