.uploadedFileDropZoneContent.dropZoneContent {
    padding: 40px;
}

.cancelBtn {
    padding: 15px 40px;
    font-size: 14px;
    font-weight: 600;
    color: #4169e1;
}

.confirmButton {
    padding: 15px 40px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 25px;
}

.teamMngmnt {
    padding-bottom: 16px;
    height: calc(100vh - 445px);
    overflow: auto;
}

.documentUpload {
    padding-right: 10px;
}

.edit-user-details {
    min-height: calc(100vh - 320px);
    max-height: calc(100vh - 320px);
    padding: 24px;
}

.no-doc-uploaded {
    padding: 16px 20px;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    width: max-content;
}

.circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-wrap: wrap; */
}

.containerDiv.hierarchyHeaderMang {
    display: flex;
    padding-top: 15px;
    width: 100%;
}

.containerDiv.hierarchyHeaderMang .filterMainDiv {
    width: calc(100% - 400px);
    overflow-x: auto;
    overflow-y: clip;
}

.rightSideActionContainer {
    width: 255px;
}

.containerDiv.hierarchyHeaderMang .filterMainDiv::-webkit-scrollbar {
    height: 5px;
}

.containerDiv.hierarchyHeaderMang .filterMainDiv::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.containerDiv.hierarchyHeaderMang .filterMainDiv::-webkit-scrollbar-thumb {
    background: rgb(118, 118, 118);
    border-radius: 20px;
}

.containerDiv.hierarchyHeaderMang .filterMainDiv::-webkit-scrollbar-thumb:hover {
    background: rgb(118, 118, 118);
}

@media (max-width: 767px) {
    .edit-user-details {
        min-height: calc(100vh - 373px);
        max-height: calc(100vh - 373px);
    }
    .teamMngmnt {
        height: calc(100vh - 520px);
    }
    .teamMngmnt .customTextField,
    .teamMngmnt .customMultiSelect,
    .teamMngmnt .customAutoComplete,
    .teamMngmnt .customDatePicker {
        padding-bottom: 20px;
    }
}
