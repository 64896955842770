.attendenceDetailsContainer {
    height: calc(100vh - 280px);
    overflow-y: auto;
    position: relative;
}
.attendenceDetailsCardContainer {
    display: flex;
    flex-wrap: wrap;

    /* justify-content: space-between; */
}

.attendenceDetailsHeading {
    font-size: 18px;
    font-family: "Montserrat", sans-serif;
    padding-bottom: 10px;
}
.attendenceDetailsCard {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    width: calc(25% - 20px);
    margin-bottom: 20px;
    margin-right: 20px;
}
.attendenceDetailsCard.bgColor {
    background-color: #e6f5eb;
}
.userProfileCircleRow {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
}
.userProfileCircle {
    background-color: #eee;
    width: 32px;
    height: 32px;
    padding: 14px 16px;
    border-radius: 25px;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.attendenceDetailsCard.bgColor .userProfileCircle {
    background-color: #fff;
}
.userProfileStatus {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: red;
}
.userProfileStatus.active {
    background-color: #0eaa57;
}
.userProfileName {
    padding: 0px 10px;
}
.punchInOutRowContainer {
    display: flex;
    justify-content: space-between;
    max-height: 135px;
    overflow-y: auto;
}
.punchInOut {
    padding-bottom: 10px;
}
.punchInOut p {
    padding-bottom: 10px;
}

.punchInOut h6 {
    font-weight: 600;
}

/* .tableContainer.emplStatusSales .customTable.tableCustomeHeightClass{
    height: calc(100% - 23px) !important;
} */

.punchInRowContainer,
.punchOutRowContainer {
    display: flex;
    flex-direction: column;
}
.tableContainer.emplStatusSales {
    height: calc(100vh - 340px);
}
.noDataFound {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 767px) {
    .attendenceDetailsCard {
        width: 100%;
        margin-right: 0px;
    }
    .punchInOutRowContainer {
        max-height: initial;
    }
}
