button {
    border: none;
    background: none;
}

.montSerratFont {
    font-family: Montserrat, sans-serif;
}

.notoSansFont {
    font-family:
        Noto Sans,
        sans-serif;
}

.fontSize24 {
    font-size: 24px;
}

.fontSize30 {
    font-size: 30px;
}

.fontSize32 {
    font-size: 32px;
}

.fontSize40 {
    font-size: 40px;
}

.fontSize16 {
    font-size: 16px;
}

.fontSize18 {
    font-size: 18px;
}

.fontSize14 {
    font-size: 14px;
}

.fontSize13 {
    font-size: 13px;
}

.fontSize12 {
    font-size: 12px;
}

.fontSize11 {
    font-size: 11px;
}

.paddingTop32 {
    padding-top: 32px;
}

.paddingTop40 {
    padding-top: 40px;
}

.paddingTop24 {
    padding-top: 24px;
}

.paddingTop18 {
    padding-top: 18px;
}

.paddingTop20 {
    padding-top: 20px;
}

.paddingTop16 {
    padding-top: 16px;
}

.paddingTop12 {
    padding-top: 12px;
}

.paddingBottom20 {
    padding-bottom: 20px;
}
.paddingBottom10 {
    padding-bottom: 10px;
}
.p-b-15 {
    padding-bottom: 15px;
}

.pr-13px {
    padding-right: 13px;
}

.pr-30 {
    padding-right: 30px;
}

.pl-30 {
    padding-left: 30px;
}

.px-40 {
    padding: 0 40px;
}

.textColorGray {
    color: #676767;
}

.textColorLightGray {
    color: #b9b9b9;
}

.textColorBlue {
    color: #4169e1;
}

.textColorBlack {
    color: #000000;
}

.textColor333 {
    color: #333;
}

.textColorGreen {
    color: #0eaa57;
}

.textColorRed {
    color: #ea3a3d;
}

.textCapitalize {
    text-transform: capitalize;
}

.fontMedium {
    font-weight: 500;
}

.fontSemiBold {
    font-weight: 600;
}

.fontBold {
    font-weight: 700;
}

.fontLight {
    font-weight: 300;
}

.fontWeight200 {
    font-weight: 200;
}

.fontWeightNormal {
    font-weight: normal;
}

.cursorPointer {
    cursor: pointer;
}

.widthFit {
    width: fit-content;
}

.paddingTop14 {
    padding-top: 14px !important;
}

.minWidth {
    min-width: 1160px;
}

.maxWidthPer70 {
    max-width: 70%;
}

.maxWidthPer60 {
    max-width: 60%;
}

.maxWidthPer50 {
    max-width: 50%;
}

.maxWidthPer40 {
    max-width: 40%;
}

.maxWidthPer20 {
    max-width: 20%;
}

.maxWidthPer30 {
    max-width: 30%;
}

.minWidth {
    min-width: 100%;
}
.MuiInputBase-input.MuiFilledInput-input.Mui-disabled {
    -webkit-text-fill-color: #000;
}

.customPrimaryButton {
    padding: 16px 0;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #ffffff;
    background-color: #4169e1;
    border-radius: 8px;
    border: none;
}

.customSecondaryButton {
    padding: 11px 24px;
    font-size: 12px;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #000000;
    background-color: #ffffff;
    border-radius: 30px;
    border: 1px solid #4169e1;
    line-height: 1;
    display: flex;
}

.customCreateButton {
    padding: 11px 16px;
    font-size: 12px;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #ffffff;
    background-color: #4169e1;
    border-radius: 30px;
    border: 1px solid #4169e1;
    line-height: 1;
    display: flex;
}

.resetBtn {
    height: 30px;
    color: #4169e1;
    /* padding: 0; */
    width: fit-content;
    /* font-weight: 500; */
}

.viewMoreButton {
    padding: 16px 60px;
    font-size: 14px;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #ffffff;
    background-color: #4169e1;
    border-radius: 25px;
    border: none;
    line-height: 1;
    display: flex;
}

/* Customize Otp Input */
.custom-Otp-Input input {
    font-family: Montserrat, sans-serif;
    width: 60px !important;
    border: 1px solid #d6d6d6;
    height: 55px;
    border-radius: 5px;
    color: #000;
    font-size: 18px;
    font-weight: 500;
}

.custom-Otp-Input div {
    justify-content: space-evenly;
}

/* DropDown */
.customDropDownFilter .MuiPaper-root.MuiPopover-paper.MuiMenu-paper {
    padding: 24px;
    margin-top: 6px;
    background: #fffefb 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 14px #0000001f;
}

.customDropDownFilter .MuiList-root.MuiMenu-list {
    padding: 0;
}

.customDropDownFilter .MuiSvgIcon-root {
    width: 16px;
    height: 16px;
}

.customDropDownFilter .MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-family:
        Noto Sans,
        sans-serif;
    font-size: 12px;
    color: #000000;
}

.transform180 {
    transform: rotate(-180deg);
}
.react-datepicker__navigation {
    width: 25px !important;
    height: 25px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 12px;
    background: none; /* Remove any background */
    border: none; /* Ensure no extra border */
}

.react-datepicker__navigation--previous {
    left: 8px;
}

.react-datepicker__navigation--next {
    right: 8px;
}

.react-datepicker__navigation-icon {
    width: 12px;
    height: 12px;
    border: solid #b0b0b0; /* Set the color */
    border-width: 2px 2px 0 0;
    transform: rotate(-135deg); /* Fix arrow orientation */
    margin: auto;
}

.react-datepicker__navigation--next .react-datepicker__navigation-icon {
    transform: rotate(45deg); /* Ensure right arrow is correct */
}

/* Custom Search  */
.customSearch {
    margin: 0;
    display: flex;
    justify-content: flex-end;
}

.customSearch .input-holder .search-icon {
    padding: 12px;
}

.rulemangementSearch.search-wrapper.active {
    min-height: 45px;
    margin-bottom: 20px;
    width: auto;
}

.customSearch.active .input-holder {
    background-color: #ffffff;
    border: 1px solid #d6d6d6;
}

.customSearch .input-holder .search-icon span::before {
    background-color: #000;
}

.customSearch .input-holder .search-icon span::after {
    border: 2px solid #000;
}

.customSearch .input-holder .search-icon span {
    transform: rotate(-45deg);
}

.search-wrapper .input-holder .search-icon .customSearch .search-icon {
    background-color: #ffffff;
}

.zoomInEffect {
    transition: transform 1s;
    cursor: pointer;
}

.zoomInEffect:hover {
    transform: scale(1.1);
    filter: brightness(0.9);
}

.opacity-50 {
    opacity: 50%;
}

.opacity-100 {
    opacity: 100%;
}

.modalButton {
    padding: 16px 32px;
    border-radius: 8px;
    background-color: #4169e1;
    border: none;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
}

.welldoneLottie {
    width: 125px;
    height: 125px;
}

.MuiBox-root {
    outline: none;
}

.modalCloseIconPlacement {
    position: absolute;
    top: -30px;
    right: -16px;
}

.successPopupCloseIconPlacement {
    position: absolute;
    top: 16px;
    right: 10px;
}

.errorMsg {
    position: absolute;
    z-index: 4;
    font-size: 12px;
    font-weight: 500;
    bottom: -14px;
}

/* AG Grid Table Customization */
.customTable .ag-root-wrapper {
    border-radius: 12px 12px 0 0;
    border: none !important;
    font-family: "Noto Sans", sans-serif;
}

.customTable .ag-header-container,
.customTable .ag-header-viewport {
    background-color: #dae1f9;
}

.customTable .ag-header.ag-header-allow-overflow .ag-header-row {
    font-size: 14px;
    color: #000000;
}

.customTable .ag-ltr .ag-cell {
    font-size: 12px;
    color: #000000;
}

.customTable .ag-row-hover {
    --ag-row-hover-color: #fff3ff;
}

.ag-theme-alpine .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
    border: none !important;
}

.tableActionButtons {
    border: none;
    background-color: inherit;
    display: flex;
    align-items: center;
    padding: 8px 6px;
    width: max-content;
}

.actionBtnContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 33px 33px 40px;
    align-items: center;
    gap: 5px;
}

.h100 {
    height: 100%;
}

.viewAndEditBtn {
    background-color: #7d96ff;
    border-radius: 20px;
    width: 30px;
    height: 30px;
}

.tableActionButtons #edit {
    padding: 0 2px;
}

.breadCrumbs {
    font-family: "Noto Sans", sans-serif;
    font-size: 12px;
    color: #161138;
}

.breadCrumbs:hover {
    color: #161138;
}

.statusActive {
    background-color: #ceeedd;
    border: 1px solid #0eaa57;
    color: #0eaa57;
    padding: 3px 12px;
    border-radius: 5px;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    line-height: 1;
}

.statusInActive {
    background-color: #ffd5d5;
    border: 1px solid #ea3a3d;
    color: #ea3a3d;
    padding: 3px 12px;
    border-radius: 5px;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    line-height: 1;
}

.logout-modal {
    width: 25%;
}

.statusInActiveDanger {
    background-color: #fef1db;
    border: 1px solid #f7b959;
    color: #ff9b00;
    padding: 3px 12px;
    border-radius: 5px;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    line-height: 1;
}

/* Ag Grid React Filter Customization */
.ag-theme-alpine .ag-filter {
    background-color: #fffefb; /* Light creamy white */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15); /* Soft shadow */
    padding: 16px;
    width: 260px; /* Adjust width */
}

.ag-theme-alpine .ag-filter-header-container {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}
.ag-theme-alpine .ag-filter-filter {
    /* background: #f6f6f6; */
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 8px;
    font-size: 14px;
    width: 100%;
}
.ag-theme-alpine .ag-set-filter-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 10px 0;
}

.ag-theme-alpine .ag-filter-apply-panel {
    padding: 10px 0 0 !important;
}

.ag-theme-alpine .ag-set-filter-item {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    color: #333;
}

.ag-theme-alpine .ag-menu {
    border-radius: 8px !important;
}

/* Improve checkbox styling */
.ag-theme-alpine .ag-set-filter-item input[type="checkbox"] {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 2px solid #aaa;
    transition: all 0.2s ease-in-out;
}

.ag-theme-alpine .ag-set-filter-item input[type="checkbox"]:checked {
    background-color: #0057ff;
    border-color: #0057ff;
}
.ag-theme-alpine .ag-standard-button {
    border-radius: 20px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: bold;
}

/* Reset Button */
.ag-theme-alpine .ag-standard-button[ref="resetFilterButton"] {
    border: 1px solid #4169e1;
    background: white;
    color: #4169e1;
    border-radius: 6px;
    padding: 6px 18px;
}
.ag-theme-alpine .ag-standard-button[ref="resetFilterButton"]:hover {
    border: 1px solid #4169e1;
    background: white;
    color: #4169e1;
}
/* Clear Button */
.ag-theme-alpine .ag-standard-button[ref="clearFilterButton"] {
    border: 1px solid #4169e1;
    background: white;
    color: #4169e1;
    border-radius: 6px;
    padding: 6px 18px;
}
.ag-theme-alpine .ag-standard-button[ref="clearFilterButton"]:hover {
    border: 1px solid #4169e1;
    background: white;
    color: #4169e1;
}

/* Apply Button */
.ag-theme-alpine .ag-standard-button[ref="applyFilterButton"] {
    background: #4169e1;
    color: white;
    border-radius: 6px;
    padding: 6px 18px;
}
.ag-theme-alpine .ag-standard-button[ref="applyFilterButton"]:hover {
    background: #4169e1;
    color: white;
}

.ag-theme-alpine .ag-standard-button:focus,
.ag-theme-alpine .ag-standard-button:active {
    outline: none !important;
    box-shadow: none !important;
}

.ag-theme-alpine .ag-simple-filter-body-wrapper {
    padding: 0 0 3px 0 !important;
}

.tableStatusContainer {
    height: 100%;
    display: flex;
    align-items: center;
}

.customModal .MuiBackdrop-root {
    background-color: rgb(0 0 0 / 85%);
}

.customDateRangePicker .react-datepicker-popper {
    transform: none !important;
    inset: auto auto auto auto !important;
    right: 0px !important;
}

.SalesOverviewDateRangePicker .customDateRangePicker .react-datepicker-popper {
    transform: translate(-140px, -10px) !important;
}

.react-datepicker__triangle,
.react-datepicker__aria-live,
.customDateRangePicker .react-datepicker-wrapper {
    display: none;
}

p {
    margin: 0;
}

.min-w-150 {
    min-width: 150px;
}
.max-w-200 {
    min-width: 180px;
    max-width: 180px;
}

.max-w-100 {
    min-width: 140px;
    max-width: 140px;
}

.grayClassDot {
    margin-right: 6px;
    font-size: 14px;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: #d6d6d6;
}

.textColorLighterGray {
    color: #f2f2f2;
}

.bg-f5f5f5 {
    background-color: #f5f5f5;
}

.cardBgGreen {
    background-color: #e6f5eb;
}

.cardBgRed {
    background-color: #fdeae7;
}

.cardBgGray {
    background-color: #f2f2f2;
}

/* View Page Tab CSS */
.customTabPanels .MuiBox-root {
    padding: 24px 0 0 0 !important;
}

.customTabPanels .brandUploadProgress.MuiBox-root,
.customTabPanels .brandUploadProgress.MuiBox-root .MuiBox-root {
    padding: 0px !important;
}

.customTab .MuiButtonBase-root.MuiTab-root {
    font-size: 12px;
    font-family:
        Noto Sans,
        sans-serif;
    color: #676767;
    text-transform: capitalize;
    padding: 10px 30px;
}

.fixed-filter {
    width: 89%;
    position: fixed;
    z-index: 4;
    top: 85px;
    background-color: #ecf0fc;
    padding-bottom: 5px;
    padding-right: 10px;
}

.customTab .MuiTabs-scroller {
    background-color: #fff;
    border-radius: 40px;
}

.customTab .MuiTabs-flexContainer {
    justify-content: space-between;
    padding: 5px;
    overflow-x: auto;
}

.h-fit {
    height: fit-content;
}

.customTab .MuiTabs-flexContainer::-webkit-scrollbar {
    height: 3px;
}

.customTab .MuiTabs-flexContainer::-webkit-scrollbar-thumb {
    background: #888;
}

.customTab .Mui-selected {
    background-color: #7d96ff;
    border-radius: 25px;
    color: #fff !important;
}

.customTab .MuiTabs-indicator {
    display: none;
}

.customTab.MuiTabs-root {
    width: max-content;
}

.scrollChange::-webkit-scrollbar {
    height: 0px;
    width: 0px;
    background: transparent;
}

.scrollChange::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
}

.scrollChange::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
}

.customScroll::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    background: #67676757;
    border-radius: 10px;
}

.customScroll::-webkit-scrollbar-track {
    border-radius: 10px;
}

.customScroll::-webkit-scrollbar-thumb {
    background: #676767;
    border-radius: 10px;
}

.cursor-pointer {
    cursor: pointer;
}

.linearProgressContainer .MuiLinearProgress-root {
    border-radius: 4px;
    height: 6px;
    background-color: #d6d6d6;
}

.linearProgressContainer .MuiLinearProgress-bar {
    background-color: #0eaa57 !important;
    border-radius: 4px;
}

#filled-basic-Enter\ Password,
#filled-basic-New\ Password,
#filled-basic-Confirmed\ Password {
    padding-right: 45px;
}

.Brand.Management {
    position: relative;
    left: -4px;
}

.customStepper {
    background-color: white;
    padding: 20px 24px;
    border-radius: 24px;
    box-shadow: 12px 0 18px #0000001a;
    overflow: auto;
}

.customStepper .MuiSvgIcon-root.MuiStepIcon-root.Mui-completed {
    color: #0eaa57;
}

.MuiButtonBase-root.MuiIconButton-root {
    margin-right: -5px !important;
}

.MuiStepConnector-line {
    border: none !important;
}

.customStepper .Mui-completed {
    cursor: pointer;
}

.customStepper .MuiSvgIcon-fontSizeMedium.Mui-completed {
    color: #0eaa57;
    cursor: pointer;
}

.customStepper .customStepperBox {
    overflow-x: auto;
}

.customStepper .customStepperBox .customStepperEachCont {
    min-width: max-content;
}

.filterBtn {
    border: none;
    background: inherit;
    padding-left: 20px;
}

.error {
    color: #d32f2f;
    font-size: 12px;
    padding: 5px 5px 0px 5px;
    position: absolute;
    bottom: 0px;
}

.inputBottomSpace {
    position: relative;
    padding-bottom: 20px;
    /* overflow: hidden; */
    height: fit-content;
}

.inputDateRangeBottomSpace {
    padding-bottom: 20px;
    height: fit-content;
}

.inputDateRangeBottomSpace .customDateRangePicker .react-datepicker-popper {
    right: initial !important;
}

.inputDateRangeBottomSpace .customTextField .MuiInputBase-input.MuiFilledInput-input {
    position: relative;
    padding-right: 40px;
    cursor: pointer;
    width: 100%;
}

.calendar_icon_img {
    position: absolute;
    right: 20px;
}

.customTable.tableCustomeHeightClass {
    height: 100% !important;
}

.passReport {
    width: fit-content;
    padding: 6px;
    background-color: #0eaa57;
    border-radius: 25px;
    height: fit-content;
}

.errorReport {
    width: fit-content;
    padding: 6px;
    background-color: #f7b959;
    border-radius: 25px;
    height: fit-content;
}

.failedReport {
    width: fit-content;
    padding: 6px;
    background-color: #ea3a3d;
    border-radius: 25px;
    height: fit-content;
}

.successBg {
    background-color: #0eaa57;
}

.comming_soon_cont {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
}

/* width */
.customeScroll::-webkit-scrollbar {
    width: 5px;
    height: calc(100% - 20px);
}

/* Track */
.customeScroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px grey;
    border-radius: 10px;
}

/* Handle */
.customeScroll::-webkit-scrollbar-thumb {
    background: rgb(118, 118, 118);
    border-radius: 10px;
}

/* Handle on hover */
.customeScroll::-webkit-scrollbar-thumb:hover {
    background: rgb(118, 118, 118);
}

/* bottom croll bar cutom */
.customeBottomScroll::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    background: rgb(118, 118, 118);
}

/* Track */
.customeBottomScroll::-webkit-scrollbar-track {
    background: rgb(247, 246, 246);
}

/* Handle */
.customeBottomScroll::-webkit-scrollbar-thumb {
    background: rgb(118, 118, 118);
}

/* Handle on hover */
.customeBottomScroll::-webkit-scrollbar-thumb:hover {
    background: rgb(118, 118, 118);
}

.customeBottomScroll::-webkit-scrollbar-thumb:horizontal {
    background: rgb(118, 118, 118);
    border-radius: 10px;
}

.customAutoComplete .MuiAutocomplete-root:hover .MuiAutocomplete-clearIndicator {
    display: none;
}

.Toastify__toast--success {
    background-color: #ceeedd !important;
    border: 1px solid #0eaa57;
    font-family:
        Noto Sans,
        sans-serif !important;
    font-weight: 500;
    color: #0eaa57 !important;
    border-radius: 12px !important;
}

.Toastify__toast--error {
    background-color: #ffd5d5 !important;
    border: 1px solid #ea3a3d;
    font-family:
        Noto Sans,
        sans-serif !important;
    font-weight: 500;
    color: #ea3a3d !important;
    border-radius: 12px !important;
}

.Toastify__close-button {
    top: 20px !important;
    right: 10px !important;
}

.Toastify__toast-body {
    max-width: 280px;
}

/* Always show the filter icon */
.ag-header-cell .ag-header-icon {
    opacity: 1 !important;
    visibility: visible !important;
}

.MuiSvgIcon-root.MuiStepIcon-root.Mui-active {
    color: #7d96ff;
}

.MuiSvgIcon-root.MuiStepIcon-root {
    color: #fffefb;
    border: 1px solid #f2f2f2;
    border-radius: 24px;
}

.Mui-disabled text {
    fill: #000;
}

.Mui-active text {
    fill: #fff !important;
}

.css-117w1su-MuiStepIcon-text {
    fill: #000 !important;
}

.MuiStepIcon-text,
.MuiStepLabel-label {
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family:
        Noto Sans,
        sans-serif !important;
}

.MuiAutocomplete-root .MuiFilledInput-root .MuiAutocomplete-endAdornment {
    right: 16px !important;
}

.customMultiSelect {
    /* width: 100%; */
    /* min-width: 250px;
    max-width: calc(100vw - 73em); */
    /* max-width: 300px; */
    box-sizing: border-box;
}

.customMultiSelect .MuiSelect-multiple,
.customMultiSelect .MuiSelect-select,
.customMultiSelect .MuiSelect-select.MuiInputBase-input.MuiFilledInput-input.MuiSelect-select {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.customMultiSelect .MuiInputBase-root.MuiFilledInput-root {
    padding-right: 10px;
}

.customMultiSelect .MuiInputBase-root.MuiFilledInput-root.customSelect {
    background-color: #000;
    opacity: 0.1;
}

.customAutoComplete.disabled .MuiAutocomplete-root .MuiFilledInput-root.MuiInputBase-sizeSmall,
.customTextField.disabledClass .MuiInputBase-input.MuiFilledInput-input {
    opacity: 0.5;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.12);
}

.customTable.ag-theme-alpine .ag-ltr .ag-cell {
    display: flex;
    align-items: center;
}

.emptyDocumentCont {
    display: flex;
    flex-wrap: wrap;
}

.emptyDocumentCont .no-doc-uploaded {
    max-width: 100%;
    width: auto;
}

.mobileNone {
    display: flex !important;
}
.mobileBlock {
    display: none !important;
}

.copySameAsAbove {
    padding-left: 15px;
}

.copySameAsAbove label {
    padding-left: 5px;
    cursor: pointer;
}

.reset_filters_button {
    background: white;
    padding: 11px;
    align-items: center;
    color: #4169e1;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    border-radius: 25px;
}

.reset_filters_button .RefreshOutlined {
    fill: #4169e1;
    width: 24px;
    height: 24px;
}

.ag-cell-value {
    line-height: 1.2;
}

@media screen and (max-width: 1400px) {
    .customTab .MuiBox-root {
        max-width: 100%;
        overflow-x: scroll;
    }

    .customTab.MuiTabs-root {
        width: 100%;
    }

    .customTab .MuiTabs-flexContainer {
        max-width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .IncentiveFilterCont {
        flex-wrap: wrap;
        justify-content: end;
    }
    .customDatePicker.customeClass .MuiInputBase-root .MuiInputBase-input {
        min-width: 90px !important;
    }
    .customCreateButton {
        min-width: 135px;
    }
    .reset_filters_text {
        display: none;
    }
    .mobileNone {
        display: none !important;
    }
    .mobileBlock {
        display: flex !important;
    }
    /* button */
    .customSecondaryButton.cancelBtn {
        padding: 10px 15px;
        font:
            normal normal 600 14px / 18px Noto Sans,
            sans-serif;
    }
    .customCreateButton.save_change {
        padding: 10px 15px;
        height: auto;
    }
    .customTabPanels .customCreateButton.save_change {
        height: auto;
        min-width: auto;
    }

    /* bredcum */
    .breadCrumbs {
        min-height: 40px;
    }

    .pr-30 {
        padding-right: 15px;
        padding-left: 15px;
    }

    .headerPosition .font32 {
        font-size: 18px;
    }

    .mainContainer .tableContainer {
        height: calc(100vh - 250px);
    }

    .headerPosition .customHeader {
        width: calc(100vw - 30px);
        padding: 10px 0px 20px 0px !important;
    }

    .headerPosition.appContainerMaxWidth {
        margin-left: initial;
        width: 100%;
    }
    .headerPosition .app-main__inner-custom {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 20px;
    }
    .headerPosition .mainContainer {
        max-width: 100vw;
    }
    .headerPosition .cardBrandManagementContainer {
        grid-template-columns: initial;
    }

    .headerPosition .cardsContainer {
        grid-template-columns: initial;
    }
    .headerPosition .cardsContainer .cardBody {
        min-width: 100%;
    }
    .headerPosition .cardsContainer .cardBody #top5States {
        flex-wrap: wrap;
    }
    .headerPosition .storeDashboardFilter {
        grid-template-columns: initial;
        display: flex;
        width: 100%;
        align-items: self-start;
        overflow: auto;
    }
    .headerPosition .FilterSubCont {
        /* flex-wrap: wrap; */
    }
    .headerPosition .lastFilterSubCont {
        padding-top: 15px;
    }

    .headerPosition .cardsContainer .cardBody .stateBusinessData,
    .headerPosition .cardsContainer .cardBody .stateBusinessDataHeading {
        grid-template-columns: 20% 20% 35% 25%;
    }

    .headerPosition .cardsContainer .cardBody .stateBusinessDataHeading div {
        padding: 0px 10px 0px 5px;
        overflow: hidden;
    }

    .headerPosition .cardsContainer .cardBody .stateBusinessData div {
        padding: 14px 5px 14px 5px;
    }

    .storeDashboardContainer .minWidth {
        min-width: 100% !important;
        height: auto;
    }
    .storeDashboardContainer .minWidth .FirstRow,
    .storeDashboardContainer .minWidth .secondRow,
    .storeDashboardContainer .minWidth .thirdRow,
    .storeDashboardContainer .minWidth .fourthRow {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: auto;
    }

    .storeDashboardContainer .minWidth .FirstRow .storeCountCard {
        background-position: 100% 91%;
    }
    .storeDashboardContainer .minWidth .FirstRow .storeClassCard .d-flex.paddingTop16.justify-content-between {
        flex-wrap: wrap;
    }
    .storeDashboardContainer
        .minWidth
        .FirstRow
        .storeClassCard
        .d-flex.paddingTop16.justify-content-between
        .storeClassCard {
        margin-bottom: 10px;
    }
    .storeDashboardContainer .minWidth .regionDataCard {
        flex-wrap: wrap;
    }
    .storeDashboardContainer .minWidth .barChartHeightFit {
        flex-wrap: wrap;
    }
    .storeDashboardContainer .minWidth .barChartHeightFit canvas {
        width: 100% !important;
    }

    .customTabPanels .formContainer,
    .customTabPanels .documentUpload {
        display: block;
        grid-template-columns: 100%;
    }
    .customTabPanels .documentUpload .uploadCard {
        margin-bottom: 20px;
    }

    .brandContactsDetals {
        padding-bottom: 50px;
    }
    .customTabPanels .saveChangeCont {
        max-height: initial;
    }
    .customTabPanels .saveChangeCont .storeDetailsContainer {
        min-height: initial;
        padding: 20px;
    }
    .customTabPanels .saveChangeCont .storeDetailsContainer .formContainer.brandDetails_cont,
    .customTabPanels .saveChangeCont .storeDetailsContainer .brand_contact_tab,
    .customTabPanels .saveChangeCont .storeDetailsContainer .addressDetailsMainCont,
    .customTabPanels .saveChangeCont .storeMagDetailsContainer .formContainer,
    .customTabPanels .saveChangeCont .storeMagDetailsContainer .formContainerNew {
        height: calc(100vh - 435px);
    }
    .customTabPanels .saveChangeCont .storeDetailsContainer .addressDetailsMainCont,
    .customTabPanels .saveChangeCont .storeMagDetailsContainer .formContainerNew.addressDetailsMainCont {
        max-height: initial;
    }

    .customTabPanels .saveChangeCont .storeMagDetailsContainer .formContainerNew .formContainer {
        height: initial;
    }

    .customTabPanels .tabheader {
        padding-right: 155px;
        padding-bottom: 20px;
        min-height: 65px;
        padding-top: 0px;
        display: flex;
        align-items: center;
    }
    .customTabPanels .tabheader .editStoreBtn {
        top: -44px;
    }
    .customTabPanels .storeOwnerDetails {
        grid-template-columns: 50% 50%;
    }

    /* store */
    .mainContainer .containerDiv {
        grid-template-columns: 25% 75%;
    }
    .customTabPanels .saveChangeCont .storeMagDetailsContainer {
        padding: 20px;
    }

    .bg-white.createStoreFormCard {
        min-height: initial;
    }
    .bg-white.createStoreFormCard.documentUploadCont .saveChangeCont.scrollChange {
        max-height: initial;
    }
    .bg-white.createStoreFormCard .formContainer,
    .bg-white.createStoreFormCard .brandContactsDetals,
    .bg-white.createStoreFormCard .addressDetailsMainCont {
        max-height: calc(100vh - 492px);
        display: block;
    }
    .bg-white.createStoreFormCard .uploadedDocuments_cont.documentUpload,
    .bg-white.createStoreFormCard.documentUploadCont .formContainerNew {
        max-height: calc(100vh - 492px);
    }

    .total_Count_Present_cont {
        flex-direction: column;
    }
    .total_Count_Present_cont .total_Count_Pdding {
        padding-bottom: 20px;
    }
    .incentiveFormContainer {
        display: block !important;
    }
    .delete-row {
        float: right;
        margin-bottom: 10px;
    }
}
