.brandDataContainer {
    display: grid;
    grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
    gap: 30px;
}

.brandCard {
    background-color: #fff;
    padding: 24px 16px;
    border-radius: 24px;
    position: relative;
}

.imgDiv {
    padding: 9px 30px;
    border: 1px solid #f8f8f8;
    border-radius: 12px;
}

.brandDetailsDiv {
    padding-bottom: 8px;
    display: flex;
    flex-flow: column;
    align-items: center;
    border-bottom: 1px dashed #d6d6d6;
}

.regionalDataDiv {
    padding-top: 8px;
    color: #000000;
}

.displayRegions {
    display: grid;
    grid-template-columns: 23.5% 23.5% 23.5% 23.5%;
    justify-content: space-between;
    gap: 10px;
}

.displayRegions div {
    border-right: 1px dashed #d6d6d6;
}

.displayRegions div:last-child {
    border: none;
}

.redirectIcon {
    /* width: 36px;
    height: 36px; */
    background: #f2f2f2 0% 0% no-repeat padding-box;
    border: 1px solid #f2f2f2;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 12px;
    top: 12px;
}

.redirectIcon img {
    padding: 9px 9px 12px 12px;
}

.redirectIcon button {
    padding: 0;
    border: 1px solid #f2f2f2;
    border-radius: 25px;
}
