.reportee-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 25px;
}
.level-container{
    display: flex;
    justify-content: center;
    align-items: center;
}
/* .level-container:first-child{
    padding-left: 5px;
} */
.level-container:first-child .reportee-container{
    padding-left: initial;
}
.level-container:first-child .reportee-container .circle{
    margin-left: 5px;
}