.brandImage {
    border: 1px solid #F2F2F2;
    border-radius: 8px;
    width: fit-content;
    padding: 8px 38px;
}

.brandImage.brandLogoImage {
    padding: 10px;
    width: 100px;
    max-height: 60px;
    display: flex;
    justify-content: center;
}

.brandImage.brandLogoImage img {
    max-width: 100%;
    max-height: 38px;
}

.storeValue a:hover {
    color: #4169E1;
}

.storeValue a {
    color: #4169E1;
}

.storeValue a.brand_deck_a:hover {
    color: #000;
}

.storeValue a.brand_deck_a {
    color: #000;
}

.storeDetailsContainer {
    border-radius: 16px;
    padding: 40px;
    min-height: calc(100vh - 230px);
    max-height: calc(100vh - 230px);
    color: #000000;
    box-shadow: 10px 10px 14px #0000001F;
    overflow-y: scroll;
}

.saveChangeCont {
    max-height: calc(100vh - 215px);
    overflow-y: auto;
}

.saveChangeCont .storeDetailsContainer {
    min-height: calc(100vh - 331px);
    max-height: initial;
    padding: 20px 40px;
    overflow-y: initial;
}

.saveChangeCont .storeDetailsContainer .formContainer {
    overflow: initial;
    max-height: initial;
}

.saveChangeCont .storeDetailsContainer .formContainer.brandDetails_cont {
    height: calc(100vh - 407px);
    overflow: auto;
}

.saveChangeCont .storeDetailsContainer .formContainer.tekxContactDetails_cont {
    max-height: calc(100vh - 410px);
    overflow-y: auto;
}

.uploadedDocuments_cont {
    max-height: calc(100vh - 400px);
    overflow-y: auto;
}

.uploadedDocuments_cont.documentUpload {
    max-height: calc(100vh - 452px);
}

.saveChangeCont .storeDetailsContainer .formContainer.addressDetailsMainCont {
    grid-template-columns: auto;
}

.saveChangeCont .storeDetailsContainer .formContainer .addressDetailsCont {
    display: flex;
    flex-direction: column;
}

.brand_contact_tab {
    max-height: calc(100vh - 400px);
    overflow-y: auto;
}

.brand_contact_tab .formContainer {
    height: initial;
}

.storeDetailsBtnContainer {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 20px 40px;
    max-height: calc(100vh - 230px);
    color: #000000;
    box-shadow: 10px 10px 14px #0000001F;
}

.storeOwnerDetails {
    display: grid;
    grid-template-columns: 23% 23% 23% 23%;
    row-gap: 40px;
    column-gap: 20px;
    font-family: 'Noto Sans', sans-serif;
}

.storeLabel {
    font-size: 12px;
    color: #676767;
}

.storeValue {
    font-size: 14px;
    padding-top: 8px;
    word-break: keep-all;
    word-wrap: break-word;
    font-weight: 500;
}

.tabheader {
    padding-bottom: 30px;
    border-bottom: 1px dashed #D6D6D6;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0px;
    color: #000000;
}

.uploadedDocumentContainer {
    display: grid;
    grid-template-columns: 295px 295px 295px;
    gap: 24px;
}

.uploadedDocumentDetail {
    display: flex;
    align-items: center;
    padding: 16px 20px;
    border: 1px solid #F2F2F2;
    border-radius: 8px;
    max-width: 294px;
    cursor: pointer;
}

.editStoreBtn {
    position: absolute;
    top: -62px;
    right: 0;
    font-family: 'Noto Sans', sans-serif;
}

.customCreateButton.save_change {
    font: normal normal 600 14px/18px Noto Sans, sans-serif;
    min-width: 214px;
    height: 50px;
    border-radius: 25px;
}

@media(max-width: 767px){
    .storeDetailsContainer {
        padding: 20px;
        min-height: calc(100vh - 275px);
        max-height: calc(100vh - 275px);
    }
}