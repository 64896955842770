.customAutoComplete .MuiFormLabel-root.MuiInputLabel-root {
    font-family: Noto Sans, sans-serif;
    font-size: 14px;
    color: #676767;
    top: 2px;
}

/* .customAutoComplete .MuiAutocomplete-root .MuiFilledInput-root {
    padding: 15px;
} */

.customAutoComplete .MuiAutocomplete-root .MuiFilledInput-root.MuiInputBase-sizeSmall .MuiFilledInput-input {
    padding: 5.2px 4px;
}

.MuiPaper-root.MuiAutocomplete-paper {
    font-size: 14px;
    font-family: Noto Sans, sans-serif;
}

.customAutoComplete .MuiAutocomplete-root .MuiFilledInput-root.MuiInputBase-sizeSmall {
    background-color: #FFF;
    border: 1px solid #D6D6D6 !important;
    border-radius: 8px;
}

.customAutoComplete .MuiAutocomplete-root .MuiFilledInput-root.MuiInputBase-sizeSmall .MuiFilledInput-input {
    font-family: Noto Sans, sans-serif;
    font-size: 14px;
    color: #000000;
    font-weight: 600;
}