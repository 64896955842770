.notification-cont {
    background-color: white;
    border-radius: 16px;
    padding: 24px;
    margin-top: 16px;
}
.noti-form-cont {
    height: calc(100vh - 320px);
    overflow-y: auto;
}
.notification-form {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    gap: 20px;
}
.notification-body textarea {
    resize: none;
    width: 100%;
    border: 1px solid #d6d6d6 !important;
    border-radius: 8px;
    padding: 10px;
    outline: none;
}

.input-p-r .customTextField .MuiInputBase-root.MuiFilledInput-root {
    padding-right: 0;
}
.notification-list {
    padding: 24px 32px;
    width: 380px;
}
.noti-content {
    max-height: 480px;
    overflow: auto;
}
.left-text {
    text-align: left;
}

.notiBg1 {
    background-color: #ffe8ff;
}
.notiBg2 {
    background-color: #fcfad7;
}
.notiBg3 {
    background-color: #dae0fc;
}

.red-dot {
    background: #ff0000;
    padding: 3px;
    border-radius: 50px;
    width: 2px;
    top: 15px;
    position: absolute;
    right: 16px;
}

.noti-detail {
    padding: 10px;
    display: flex;
    border-radius: 15px;
    align-items: center;
    margin-bottom: 12px;
}
.MuiAvatar-root {
    font-family:
        Noto Sans,
        sans-serif !important;
}

.noti-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media screen and (max-width: 767px) {
    .notification-cont {
        margin-top: 0px;
    }
    .noti-form-cont {
        overflow-y: auto;
    }
    .notification-form {
        display: block;
    }
    .notification-list {
        width: 300px;
        padding: 8px 10px;
    }
    .noti-content {
        height: 300px;
    }
}
