.card-bg {
    background-color: white;
    border: 1px solid #f2f2f2;
    border-radius: 24px;
    padding: 24px;
}
.over-all {
    display: flex;
    width: 100%;
    gap: 30px;
    margin-top: 40px;
}
.top-bottom {
    display: flex;
    width: 100%;
    gap: 30px;
    margin-top: 30px;
}
.sell-thru {
    display: flex;
    width: 100%;
    gap: 30px;
    margin-top: 30px;
}
.staff {
    display: flex;
    width: 100%;
    gap: 30px;
    margin-top: 30px;
}
.over-all .card-bg:first-child {
    width: 30%;
}
.over-all .card-bg:nth-child(2) {
    width: 70%;
}
.top-bottom .card-bg:first-child {
    width: 50%;
}
.top-bottom .card-bg:nth-child(2) {
    width: 50%;
}
.sell-thru .card-bg:first-child {
    width: 30%;
}
.sell-thru .card-bg:nth-child(2) {
    width: 70%;
}
.staff .card-bg:first-child {
    width: 70%;
}
.staff .card-bg:nth-child(2) {
    width: 30%;
}
.icon-bg {
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 30px;
    width: max-content;
    margin-right: 16px;
}

.header-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rsm-bar-color,
.top-bar-color,
.staff-bar-color,
.region-bar-color,
.bottom-bar-color {
    display: flex;
    align-items: center;
}

.color-cont {
    display: flex;
    align-items: center;
}

.target {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border-radius: 2px;
}
.achievement {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border-radius: 2px;
}

.rsm-bar-color .target {
    background-color: #ffceff;
}
.rsm-bar-color .achievement {
    background-color: #91a5f9;
}

.region-bar-color .achievement {
    background-color: #ffceff;
}
.region-bar-color .target {
    background-color: #91a5f9;
}

.top-bar-color .target {
    background-color: #a6b5f4;
}
.top-bar-color .achievement {
    background-color: #d6d6d6;
}

.bottom-bar-color .target {
    background-color: #7263d6;
}
.bottom-bar-color .achievement {
    background-color: #91a5f9;
}

.staff-bar-color .target {
    background-color: #bbc5ee;
}
.staff-bar-color .achievement {
    background-color: #c9c3c3;
}

.color-cont:first-child {
    margin-right: 16px;
}

.view-detail-container {
    background-color: #fffefb;
    box-shadow: 12px 0 30px #d6d6d666;
    border-radius: 24px;
    padding: 32px 40px;
    margin-top: 16px;
    font-family:
        Noto Sans,
        sans-serif;
}

.cont-1 {
    display: flex;
    gap: 45px;
    /* justify-content: space-between; */
}

.view-detail-content {
    height: calc(100vh - 225px);
    overflow-y: auto;
    padding-right: 10px;
}

.border-dashed {
    border: 1px dashed #d6d6d6;
}

.detail-card {
    border: 1px solid #f5f5f5;
    background: #f5f5f5;
    border-radius: 12px;
    padding: 16px;
    display: grid;
    width: 100%;
    grid-template-columns: auto auto;
}

#width-of-div {
    width: 200px;
}
.monthly-detail-card {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    gap: 24px;
}

.ach-chart {
    position: absolute;
    top: -11px;
    left: -10px;
}

.value-cont {
    background-color: #f2f2f2;
    border: 1px solid #f2f2f2;
    border-radius: 12px;
    padding: 18px 24px;
}

.gray-border {
    border: 1px dashed #d6d6d6;
    margin: 8px 0;
}

.border-right {
    border-right: 1px dashed #d6d6d6;
}

.overflow-auto {
    overflow: auto;
}

.daily-data {
    display: grid;
    width: 100%;
    grid-template-columns: 23% 24% 24% 24%;
    gap: 24px;
}

.statusFilter.uploadBrandDrop {
    padding: 18px 24px;
}

.channelDisplayData.disabled,
.downloadButton.disabled {
    pointer-events: none;
    opacity: 0.6;
}

@media screen and (max-width: 1024px) {
    .over-all .card-bg:first-child {
        width: 45%;
    }
    .over-all .card-bg:nth-child(2) {
        width: 55%;
    }
    .top-bottom .card-bg:first-child {
        width: 48%;
    }
    .top-bottom .card-bg:nth-child(2) {
        width: 48%;
    }
    .sell-thru .card-bg:first-child {
        width: 40%;
    }
    .sell-thru .card-bg:nth-child(2) {
        width: 55%;
    }
    .staff .card-bg:first-child {
        width: 55%;
    }
    .staff .card-bg:nth-child(2) {
        width: 45%;
    }
    .header-cont {
        display: block;
    }
}
@media screen and (max-width: 1024px) {
    .view-detail-container {
        margin: 0;
    }
    .view-detail-content {
        height: calc(100vh - 245px);
    }
    .monthly-detail-card {
        display: block;
        margin-bottom: 20px;
    }
    .detail-cont-div {
        margin-top: 16px;
    }
    .detail-cont-div:first-child {
        margin-top: 0;
    }
    .cont-1 {
        display: block;
        text-align: left;
        grid-template-columns: auto auto;
    }
    .view-detail-container .storeLabel {
        padding-top: 10px;
    }
    .view-detail-container:first-child {
        padding-top: 0;
    }
    .view-detail-container .storeValue {
        padding: 0;
    }
    #card-parent {
        display: block;
    }
    .card-bg {
        width: 100% !important;
    }
    .card-bg:first-child {
        margin-bottom: 20px;
    }
    .header-cont {
        display: block;
    }
    .ach-chart {
        top: -10px;
        /* left: 0px; */
    }
}
@media screen and (max-width: 767px) {
    .sell-thru-data {
        display: block !important;
        width: 100%;
    }
    .sell-thru-data div {
        /* display: block !important; */
        width: 100% !important;
        border: none;
        padding: 0 !important;
    }
    .sell-thru-data div .fontSize24 {
        text-align: right;
    }
}
