.headerPosition {
    margin-left: 150px;
    min-height: 100vh;
    width: calc(100% - 150px);
    /* padding-bottom: 30px; */
}

.customHeader {
    width: calc(100vw - 180px);
    position: fixed;
    justify-content: space-between;
    z-index: 6;
}

.notoSansFont {
    font-family: 'Noto sans', sans-serif;
}

.montserratFont {
    font-family: 'Montserrat', sans-serif;
}

.font32 {
    font-size: 32px;
}

.backButton {
    padding: 10px;
    cursor: pointer;
}

.headerIcon {
    background-color: #FFFFFF;
    padding: 12px;
    border-radius: 25px;
    cursor: pointer;
}

.customDropdownCont{
    position: relative;
}

.bellIcon {
    background-color: #FFFFFF;
    padding: 14px 16px;
    border-radius: 25px;
    cursor: pointer;
    position: relative;
   
}

.dropdownCont{
    position: absolute;
    top: 60px;
    right: 0;
    min-width: 150px;
    min-height: 75px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    display: none;
}
.dropdownCont.active{
    display: block;
}

.arrow-top:after {
    content: " ";
    position: absolute;
    right: 14px;
    top: -8px;
    border-top: none;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 8px solid #fff;
}

.customHeaderBredcum{
    display: none;
}


@media screen and (max-width:767px) {
    .app-main-custom{
        padding-top: 123px;
        
    }
    .app-main-outer-custom {
        min-height: calc(100vh - 123px);
        max-height: calc(100vh - 123px);
        padding-bottom: env(safe-area-inset-bottom);
    }
    .backButton{
        padding: 0px;
        padding-right: 5px;
    }
    .customHeaderBredcum{
        display: block;
        position: fixed;
        top: 80px;
        width: calc(100vw - 30px);
        min-height: 30px;
    }
}