.createIncentiveFormCard {
    padding: 5px;
    border-radius: 24px;
    box-shadow: 12px 0 30px #0000001a;
    min-height: calc(100vh - 245px);
}

.createIncentiveHeader {
    padding: 10px 20px 4px 20px;
}

.createIncentiveFormCont {
    min-height: calc(100vh - 320px);
    max-height: calc(100vh - 320px);
    overflow-y: auto;
    padding: 20px;
}

.createIncentiveFormCard .incentiveFormContainer {
    height: auto;
}

.incentiveFormContainer {
    position: relative;
    display: grid;
    grid-template-columns: 31% 31% 31% 2%;
    gap: 22px;
}

.delete-row {
    height: 52px;
}

.incentiveLastFormContainer {
    display: grid;
    grid-template-columns: 100%;
    gap: 22px;
}

/* .incentiveMultiFormContainer{
    display: grid;
    gap: 24px;
} */

.incentiveFormContainer .customTextField .MuiInputBase-root.MuiFilledInput-root {
    padding-right: 0px;
    width: 100%;
}

.incentiveFormContainer .customMultiSelect .MuiInputBase-root.MuiFilledInput-root {
    padding-right: 0px;
    width: 100%;
}

.incentiveFormContainer .customMultiSelect .MuiInputBase-root.MuiFilledInput-root > div:last-child {
    padding-right: 16px;
}
.incentiveFormContainer .customMultiSelect .MuiFormControl-root,
.incentiveFormContainer .customMultiSelect,
.incentiveFormContainer .inputBottomSpace,
.incentiveFormContainer .inputDateRangeBottomSpace,
.incentiveFormContainer .open_calendar_open,
.incentiveFormContainer .open_calendar_open .customTextField {
    width: 100%;
}
/* .incentiveFormContainer .customMultiSelect .MuiSelect-select.MuiInputBase-input.MuiFilledInput-input.MuiSelect-select{
    padding-right: 10px;
} */

.createIncentiveFormCard .topFormContainer {
    display: grid;
    grid-template-columns: 32% 31% 32%;
    grid-gap: 22px;
    padding-right: 10px;
    padding-bottom: 24px;
}

.open_calendar_open {
    cursor: pointer;
}
