/* src/Circle.css */
.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #DAE1F9; /* Soft blue background */
  margin: 5px 0px 2px 5px;
  position: relative;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  cursor: pointer;
  margin-left: -20px;
  border: 2px solid #fff;
  font-size: 22px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
  
.circle .number {
  position: absolute;
  top: -7px;
  right: -2px;
  background-color: #7D96FF;
  border-radius: 50%;
  padding: 2px 6px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}
  
.circle .initials {
  font-weight: bold;
}

.circle .circleTooltip {
  width: auto;
  background-color: transparent;
  color: #000000;
  text-align: center;
  border-radius: 6px;
  padding: 0px 5px;
  position: absolute;
  z-index: 1;
  bottom: -40px; 
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
  text-transform: capitalize;
}

.circle:hover .circleTooltip {
  visibility: visible;
  opacity: 1;
}
  