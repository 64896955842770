.reports .ag-body-horizontal-scroll-viewport {
    height: 0 !important;
    max-height: 0;
    min-height: 0 !important;
}

.custom-report {
    overflow: auto; /* Enables scrolling */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* Hides scrollbar in IE/Edge */
}

/* Hides scrollbar in Webkit-based browsers */
.custom-report::-webkit-scrollbar {
    display: none;
}

.custom-report {
    scrollbar-width: none; /* Hides scrollbar */
    overflow: auto;
}
.custom-report {
    -ms-overflow-style: none;
}

.count-card {
    padding: 24px;
    border: 1px solid #f2f2f2;
    background-color: white;
    border-radius: 24px;
    width: 50%;
}
.count-conatiner {
    display: flex;
    margin-top: 38px;
    gap: 30px;
    width: 100%;
    min-height: 483px;
}

.cat-season-btn-cont {
    margin-top: 12px;
    background-color: #f2f2f2;
    padding: 3px;
    width: max-content;
    border-radius: 22px;
}

.cat-season-btn-cont.incentiveToggalCont {
    background-color: #fff;
}

.active-tab-btn-bg {
    background-color: #7d96ff;
    color: #fff;
    padding: 10px 30px;
    border-radius: 25px;
    font-weight: 600;
}

.tab-btn-bg {
    padding: 10px 30px;
    border-radius: 22px;
}

.download-btn {
    border: 1px solid #f2f2f2;
    background-color: #ffffff;
    border-radius: 19px;
    padding: 7px;
}

.stock-details {
    padding: 24px;
    border: 1px solid #f2f2f2;
    background-color: white;
    border-radius: 24px;
    margin-top: 30px;
}

.card-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed #d6d6d6;
    padding: 10px 0;
}

.card-data:last-child {
    border: none;
}

.item-name {
    text-transform: capitalize;
    color: #676767;
    font-family:
        Noto Sans,
        sans-serif;
    font-size: 14px;
}

.counts {
    font-family:
        Noto Sans,
        sans-serif;
    font-weight: 600;
    font-size: 24px;
}

.dashboard-container {
    margin-bottom: 24px;
}

.dot-div {
    border: 1px solid #d6d6d6;
    background-color: #d6d6d6;
    padding: 4px;
    border-radius: 20px;
    width: 12px;
    height: 12px;
}

.view-all-btn {
    font-size: 14px;
    text-transform: capitalize;
    color: #4169e1;
    text-decoration: underline;
}

@media (max-width: 767px) {
    .count-conatiner {
        display: block;
        margin-top: 44px;
    }
    .count-card {
        padding: 24px;
        border: 1px solid #f2f2f2;
        background-color: white;
        border-radius: 24px;
        width: 100%;
    }
    .count-card:first-child {
        margin-bottom: 24px;
    }
}
