.containerDiv {
    display: grid;
    grid-template-columns: 50% 50%;
}

.filterMainDiv {
    display: flex;
}

.filterDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border: 1px solid #f2f2f2;
    border-radius: 19px;
    padding: 11px 16px;
    line-height: 1;
}

.mainContainer {
    max-width: calc(100vw - 200px);
}

.importExportFile {
    background-color: #ffffff;
    border: 1px solid #f2f2f2;
    padding: 11px;
    border-radius: 50px;
    line-height: 1;
    cursor: pointer;
    width: fit-content;
    height: fit-content;
}

.tableContainer {
    padding: 14px;
    height: calc(100vh - 255px);
    border-radius: 16px;
    background: #fffefb 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 16px #d6d6d666;
}

.tableContainer .rdt_Table {
    border: none;
}

.tableContainer .rdt_TableHeadRow {
    background: #dae1f9 0% 0% no-repeat padding-box;
    border-radius: 8px 8px 0 0;
}

.tableContainer .rdt_TableRow:hover {
    background-color: #fff3ff;
}

.tableContainer .rdt_TableCell {
    border: none;
}

.tableContainer .rdt_TableHeadRow .rdt_TableCol_Sortable {
    font-size: 14px;
    font-family:
        Noto Sans,
        sans-serif;
    font-weight: 600;
    color: #000000;
}

.tableContainer .rdt_TableHeadRow .rdt_TableCol {
    border: none;
}

.tableContainer .rdt_TableBody .rdt_TableCell {
    font-family:
        Noto Sans,
        sans-serif;
    font-size: 12px;
    columns: #000000;
}

.storeDashboardFilter {
    display: grid;
    grid-template-columns: 50% 50%;
}

.statusFilter {
    padding: 9px 24px;
    font-size: 14px;
    font-weight: 400;
    min-width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 19px;
    border: none;
}

.yearFilter {
    padding: 11px 24px;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 19px;
    border: none;
    line-height: 1;
    min-width: 160px;
}

.goToMasterBtn {
    padding: 11px 24px;
    font-size: 14px;
    display: flex;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    border-radius: 19px;
    line-height: 1;
    color: #fff;
    background-color: #4169e1;
    cursor: pointer;
    min-width: 135px;
}

.lottieCss {
    width: 80px;
    height: 80px;
}

.lottieCss svg {
    height: 80px !important;
}

.welcomeUser {
    max-width: 265px;
    word-break: keep-all;
    word-wrap: break-word;
    max-height: 80px;
    color: #000;
}

.storeCountCard {
    background-image: url("../../assets/png/storeCountCard.png");
    height: 196px;
    background-repeat: no-repeat;
    border-radius: 24px;
    background-size: 100%;
}

.threeFinger {
    padding: 10px 14px;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
}

.boxShadow:hover {
    box-shadow: 10px 10px 18px #00000024;
}

.storeClassCard {
    border-radius: 24px;
    background-color: #fffefb;
    height: 100%;
}

.channelsCard {
    height: 100%;
    border-radius: 24px;
    background-color: #fffefb;
}

.regionStoreCard {
    height: 100%;
    border-radius: 24px;
    background-color: #fffefb;
}

.FirstRow {
    display: grid;
    grid-template-columns: 294px 422px auto;
    gap: 30px;
}

.secondRow {
    padding-top: 30px;
    height: 240px;
    display: grid;
    grid-template-columns: 515px auto;
    gap: 30px;
}

.brandStoreCard {
    background-color: #fffefb;
    height: 100%;
    border-radius: 24px;
    background-image: url("../../assets/svg/MaskGroup8633.svg");
    background-repeat: no-repeat;
    background-size: 100%;
}

.thirdRow {
    display: grid;
    grid-template-columns: 680px auto;
    gap: 30px;
    padding-top: 30px;
}

.fourthRow {
    display: grid;
    grid-template-columns: auto 399px 300px;
    height: 312px;
    gap: 30px;
    padding-top: 30px;
}

.classificationCard {
    border-radius: 24px;
    height: 100%;
    background-color: #fffefb;
}

.cityTierCard {
    background-color: #fffefb;
    border-radius: 24px;
    height: 100%;
}

.openingStatus {
    background-color: #fffefb;
    background-image: url("../../assets/svg/storeStatusCard.svg");
    background-repeat: no-repeat;
    border-radius: 24px;
    height: 100%;
    background-size: 100%;
}

.totalArea {
    border-radius: 24px;
    background-color: #fffefb;
    height: 100%;
}

.businessModelCard {
    border-radius: 24px;
    background-color: #fffefb;
    height: 100%;
}

.storeClassIcon {
    background-color: #f2f2f2;
    padding: 10px 14px;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
}

.storeClassCard {
    font-family:
        Noto Sans,
        sans-serif;
    margin-right: 10px;
    min-width: 80px;
}

.storeClassCard:last-child {
    margin: 0;
}

.storeClassPlus {
    background-color: #f2f2f2;
    border-radius: 8px;
    padding: 14px;
}

.storeClassPlus:hover {
    filter: brightness(0.9);
}

.classDot {
    margin-right: 6px;
    font-size: 14px;
    width: 8px;
    height: 8px;
    border-radius: 8px;
}

.greenDot {
    background-color: #0eaa57;
}

.grayDot {
    background-color: #d6d6d6;
}

.storeClassA .classDot {
    background-color: #0eaa57;
}

.storeClassA {
    border-radius: 8px;
    padding: 14px;
    background-color: #e6f5eb;
}

.storeChannel {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
}

.dottedBorder {
    border-right: 1.5px dashed #d6d6d6;
    text-align: center;
    padding: 0 10px;
}

.dottedBorder:last-child {
    border-right: none;
    padding: 0;
}

.dottedBorder:first-child {
    padding-right: 0;
    text-align: left;
}

.cardBgGreen {
    background-color: #e6f5eb;
}

.cardBgRed {
    background-color: #fdeae7;
}

.cardBgGray {
    background-color: #f2f2f2;
}

.regionDataCard {
    display: flex;
    gap: 10px;
    justify-content: space-evenly;
}

.regionData {
    border-radius: 8px;
    padding: 24px 12px;
    max-height: 108px;
    min-width: 108px;
    max-width: 108px;
}

.storeBrand {
    display: grid;
    grid-template-columns: 16.6% 16.6% 16.6% 16.6% 16.6% 16.6%;
}

.openingStatusCard {
    background-color: #f8f7f7;
    border-radius: 8px;
    padding: 40px 10px;
}

.openingStatusCard .openingStatusDot:last-child {
    padding-left: 10px;
}

.barChartDetails {
    background-color: #f8f8f8;
    border-radius: 8px;
    padding: 24px;
    height: 100%;
    min-width: 242px;
    max-width: 242px;
    max-height: 253px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

.barChartDataDetails {
    display: grid;
    border-bottom: 1.5px dashed #d6d6d6;
    align-items: center;
}

.barChartDataDetails:last-child {
    border: none;
}

.pieChartDataDetails {
    display: grid;
    border-bottom: 1.5px dashed #d6d6d6;
    padding: 10px 0;
}

.pieChartDataDetails:last-child {
    padding-bottom: 0px;
    border: none;
}

.pieChartDataDetails:first-child {
    padding-top: 0px;
}

.pirChartDot {
    height: 12px;
    width: 12px;
    border-radius: 12px;
}

.bgStoreClassfication1 {
    background-color: #4169e1;
}

.bgStoreClassfication2 {
    background-color: #6887e6;
}

.bgStoreClassfication3 {
    background-color: #8da4ea;
}

.bgStoreClassfication4 {
    background-color: #b3c2f1;
}

.bgStoreClassfication5 {
    background-color: #d9e0f6;
}

.bgGray {
    background-color: #d6d6d6;
}

.totalSqFt {
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 12px 24px;
    background-color: #f8f7f7;
    border-radius: 8px;
}

.dottedBorderRight {
    border-right: 1.5px dashed #d6d6d6;
}

.cardHeight {
    height: 253px;
}

.barChartHeightFit {
    height: calc(100% - 40px);
    justify-content: space-evenly;
}

.businessModel {
}

.dottedBorderRight:last-child {
    border-right: none;
}

.allChannelContainer {
    padding: 12px;
}

.channelDisplayData {
    display: grid;
    gap: 16px;
    grid-template-columns: auto auto auto;
}

.dataCardChannelStoreCount {
    padding: 12px;
    border-radius: 8px;
    min-width: 170px;
    max-width: 175px;
    max-height: 88px;
}

.businessModelDetails {
    display: grid;
    border-bottom: 1.5px dashed #d6d6d6;
    min-width: 196px;
    padding: 2px 0;
}

.businessModelDetails:last-child {
    padding-bottom: 0px;
    border: none;
}

.businessModelDetails:first-child {
    padding-top: 0px;
}

.uploadFileModalCloseIcon {
    position: absolute;
    top: -30px;
    right: -21px;
}

.customeProgressModal .css-15zfmqm {
    width: 835px;
}

.dropZoneContentContainer {
    display: flex;
}

.dropZoneContent {
    background-color: #fffefb;
    border: 1px solid #f2f2f2;
    border-radius: 24px;
    padding: 40px 135px;
    text-align: center;
}

.dropZoneContentRight {
    background-color: #fffefb;
    border: 1px solid #f2f2f2;
    border-radius: 24px;
}

.dropZoneContentContainer .dropZoneContent {
    padding: 35px 40px;
}

.dropZoneContentContainer .dropZoneContentRight {
    padding: 40px;
    width: 100%;
}

.progressBarSec {
    display: flex;
    align-items: center;
}

.dropZoneContentContainer .dropZoneContentRight .dropZoneImport {
    margin-right: 15px;
}

.linearProgressContainer {
    width: 100%;
}

.progressHeading {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    word-break: break-all;
    /* word-wrap: break-word; */
}

.progressHeading span {
    font-family:
        Noto Sans,
        sans-serif;
    letter-spacing: 0px;
    font-size: 12px;
    color: #676767;
}

.progressHeading span:first-child {
    min-height: 36px;
    width: calc(100% - 30px);
}

.progressHeading.bottom span:first-child {
    min-height: initial;
}
.progressHeading.bottom {
    padding-bottom: 0px;
}

.dropZoneInnerContainer {
    padding: 0 50px;
}

.dropZoneImport {
    border-radius: 40px;
    padding: 16px;
    width: fit-content;
    background-color: #f2f2f2;
    margin: auto;
}
.dropZoneImport.dropZoneImportInactive {
    display: none;
}

.customCreateButton.customCreatePropsBtnClass {
    padding: 17px 34px;
    border-radius: 25px;
}

.CustomBlueButtonConatiner {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
}

.uploadingP {
    font-family:
        Noto Sans,
        sans-serif;
    font-size: 14px;
    letter-spacing: 0px;
    color: #676767;
}

.bg1 {
    background-color: #fff4ff;
}

.bg2 {
    background-color: #edfff5;
}

.bg3 {
    background-color: #edefff;
}

.bg4 {
    background-color: #fff8ed;
}

.bg5 {
    background-color: #edf8ff;
}

.bg6 {
    background-color: #f8fff2;
}

.bg7 {
    background-color: #f5f4ff;
}

.bg8 {
    background-color: #fcffef;
}

.bg9 {
    background-color: #ecfcf5;
}

.createStoreFormCard {
    padding: 24px;
    border-radius: 24px;
    box-shadow: 12px 0 30px #0000001a;
    min-height: calc(100vh - 370px);
}

.createStoreFormCard .formContainer {
    max-height: calc(100vh - 445px);
}
.createStoreFormCard .brandContactsDetals {
    max-height: calc(100vh - 445px);
    overflow: auto;
}
.createStoreFormCard .brandContactsDetals .formContainer {
    max-height: initial;
    height: initial;
}

.documentUploadCont .formContainerNew {
    height: calc(100vh - 445px);
    padding-top: 0px !important;
}

.createStoreFormCard .formContainer.addressesDetailsCont {
    max-height: 100%;
    height: 100%;
}

.continueBtn {
    padding: 24px;
    border-radius: 24px 24px 0 0;
    box-shadow: 12px 0 30px #0000001a;
}

.confirmButton {
    padding: 12px 40px;
    width: fit-content;
    font-weight: 500;
}

.formContainer {
    display: grid;
    grid-template-columns: 32% 31% 32%;
    gap: 24px;
    height: calc(100vh - 386px);
    overflow: auto;
    padding-right: 10px;
}

.formContainerNew {
    height: calc(100vh - 386px);
    overflow: auto;
    padding-right: 10px;
}
.formContainerNew .formContainer {
    height: initial;
}

.text_truncate {
    max-width: calc(100% - 15px);
}

.filterDropdown .MuiTypography-root {
    font-family:
        Noto Sans,
        sans-serif;
    font-size: 14px;
    padding-left: 10px;
}

.filterDropdown .MuiButtonBase-root.MuiCheckbox-root {
    padding: 0;
}

.filterDropdown .MuiList-root.MuiMenu-list {
    padding: 10px;
}

.filterDropdown .MuiSvgIcon-root {
    width: 18px;
}

.filterDropdown .MuiPaper-root.MuiPopover-paper.MuiMenu-paper {
    box-shadow: 10px 10px 18px #00000024;
}

.filterDropdown .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
    color: #6b8dffcc;
}

.addressDetailsMainCont_brand {
    height: calc(100vh - 406px);
    overflow: auto;
    padding-right: 10px;
}

.documentUploadCont .addressDetailsMainCont {
    max-height: calc(100vh - 445px);
    overflow: auto;
}

.disabled-div {
    position: absolute;
    width: calc(100% - 235px);
    height: calc(100% - 435px);
    z-index: 1;
}

.customStepper .customStepperBox .customStepperEachCont .MuiStep-root {
    cursor: pointer;
}

.documentUpload {
    display: grid;
    gap: 24px;
    padding-top: 24px;
    grid-template-columns: 1fr 1fr;
}

.uploadCard {
    background-color: #f2f2f2;
    padding: 24px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    min-height: 160px;
}

.storeMagDetailsContainer {
    padding: 10px 30px 20px 30px;
    box-sizing: border-box;
    border-radius: 16px;
}

.storeMagDetailsContainer .ParentStoreDetails .inputBottomSpace {
    height: fit-content;
}

.formContainerNew.viewDocumentUpload {
    height: calc(100vh - 290px);
}

.viewDocumentUpload .documentUpload {
    grid-template-columns: 1fr 1fr 1fr;
}

.viewUploadCard {
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 20px;
    cursor: pointer;
}

.viewUploadCardNameDetails {
    padding: 0px 15px;
    width: 100%;
}

.viewUploadCardNameDetails p:first-child {
    font-size: 12px;
    letter-spacing: 0px;
    color: #000000;
}

.viewUploadCardNameDetails p:last-child {
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0px;
    color: #676767;
}

.view-file-modal {
    width: 45%;
}
.view-pdf {
    height: 80vh;
}

/* Responsive for medium screens */
@media (max-width: 768px) {
    .documentUpload,
    .viewDocumentUpload .documentUpload {
        grid-template-columns: 1fr 1fr;
    }
    .doughnutChartDetailsComp {
        flex-direction: column;
        justify-content: center;
    }
    .doughnutChartDetailsComp .doughnutChart {
        padding-right: 0px !important;
        justify-content: center;
    }
    .doughnutChartDetailsComp .doughnutChartDetails {
        padding-left: 0px !important;
        justify-content: center !important;
        padding-top: 20px;
    }
    .logout-modal,
    .view-file-modal {
        width: 95%;
    }
    .view-pdf {
        height: 70vh;
    }
    .view-pdf img {
        height: 450px;
    }
}

/* Responsive for small screens */
@media (max-width: 480px) {
    .documentUpload,
    .viewDocumentUpload .documentUpload {
        grid-template-columns: 1fr;
    }
}
