.customDatePicker .MuiFormControl-root.MuiTextField-root {
    width: 100%;
}

.customDatePicker .MuiFormLabel-root.MuiInputLabel-root,
.customDatePicker .MuiInputBase-input.MuiFilledInput-input {
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    color: #676767;
}

.customDatePicker .MuiInputBase-input.MuiFilledInput-input {
    color: #000000;
}

.customDatePicker .MuiInputBase-root.MuiFilledInput-root {
    border: 1px solid #D6D6D6;
    background-color: white;
    border-radius: 8px;
}


.customDatePicker.customClass {
    display: inline-block;
}

.customDatePicker.customeClass .MuiInputBase-root{
    border-radius: 19px;
}

.customDatePicker.customeClass .MuiInputBase-root .MuiInputBase-input{
    padding: 9px 24px;
    min-width: 120px;
    max-width: 120px;
}

/* .customDatePicker.customeClass .MuiFormControl-root{
    max-width: 200px;
} */
