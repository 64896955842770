.storeDetailsContainer {
    border-radius: 16px;
    padding: 40px;
    min-height: calc(100vh - 230px);
    max-height: calc(100vh - 230px);
    color: #000000;
    box-shadow: 10px 10px 14px #0000001F;
    overflow-y: scroll;
}

.saveChangeCont {
    max-height: calc(100vh - 215px);
    overflow-y: auto;
}

.saveChangeCont .storeDetailsContainer {
    min-height: calc(100vh - 331px);
    max-height: initial;
    padding: 20px 40px;
    overflow-y: initial;
}

.saveChangeCont .storeDetailsContainer .formContainer {
    overflow: initial;
    max-height: initial;
}

.saveChangeCont .storeDetailsContainer .formContainer.addressDetailsMainCont {
    grid-template-columns: auto;
}

.saveChangeCont .storeDetailsContainer .formContainer .addressDetailsCont {
    display: flex;
    flex-direction: column;
}

.storeDetailsBtnContainer {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    /* border-radius: 16px; */
    padding: 20px 40px;
    max-height: calc(100vh - 230px);
    color: #000000;
    box-shadow: 10px 10px 14px #0000001F;
    /* overflow-y: scroll; */
}

.storeOwnerDetails {
    display: grid;
    grid-template-columns: 23% 23% 23% 23%;
    row-gap: 40px;
    column-gap: 20px;
    font-family: 'Noto Sans', sans-serif;
}

.storeLabel {
    font-size: 12px;
    color: #676767;
}

.storeValue {
    font-size: 14px;
    padding-top: 8px;
    word-break: keep-all;
    word-wrap: break-word;
    font-weight: 500;
    color: #000;
    /* text-transform: capitalize; */
}

.tabheader {
    padding-bottom: 30px;
    border-bottom: 1px dashed #D6D6D6;
}

.uploadedDocumentContainer {
    display: grid;
    grid-template-columns: 295px 295px 295px;
    gap: 24px;
}

.uploadedDocumentDetail {
    display: flex;
    align-items: center;
    padding: 16px 20px;
    border: 1px solid #F2F2F2;
    border-radius: 8px;
    max-width: 294px;
    cursor: pointer;
}

.editStoreBtn {
    position: absolute;
    top: -62px;
    right: 0;
    font-family: 'Noto Sans', sans-serif;
}

.customCreateButton.save_change {
    font: normal normal 600 14px/18px Noto Sans, sans-serif;
    min-width: 214px;
    height: 50px;
    border-radius: 25px;
}