.reportTableList {
    display: grid;
    grid-template-columns: 58% 40%;
    column-gap: 30px;
}

.reportsTable {
    height: 100%;
}

.sales-dash-container {
    margin-top: 25px;
    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    gap: 22px;
}
.sales-card {
    padding: 24px;
    border: 1px solid #f2f2f2;
    background-color: white;
    border-radius: 24px;
    display: flex;
    align-items: baseline;
    min-width: 350px;
}

.sales-dash-container .sales-card:first-child {
    background-image: url("../../assets/png/gross-sales.png");
    background-repeat: no-repeat;
    background-size: cover;
    color: white !important;
}
.sales-dash-container .sales-card:nth-child(2) {
    background-image: url("../../assets/svg/leafUp.svg"), url("../../assets/svg/leafDown.svg");
    background-repeat: no-repeat;
    background-position:
        bottom left,
        top right;
    background-size:
        90px 55px,
        90px 55px;
}

.img-class {
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 20px;
    margin-right: 16px;
}
.sales-dash-container .sales-card:first-child .img-class {
    background-color: white;
}
.heading {
    font-family: Montserrat, sans-serif;
    font-size: 18px;
}
.sale-value {
    font-size: 32px;
    font-family: "Noto Sans", sans-serif;
    padding-top: 12px;
    font-weight: 600;
    color: #000;
}
.sales-category {
    display: grid;
    grid-template-columns: 42.5% 56.5%;
    padding-top: 30px;
    gap: 22px;
}

.gross-sales-category {
    padding: 24px;
    border: 1px solid #f2f2f2;
    background-color: white;
    border-radius: 24px;
    min-width: 640px;
    /* max-width: 640px; */
    width: 100%;
}

.active-btn {
    background-color: #4169e1;
    color: #fff;
    padding: 10px 30px;
    border-radius: 22px;
}

.tab-btn-container {
    padding: 3px;
    background-color: #f2f2f2;
    border-radius: 40px;
    min-width: 300px;
    max-width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tab-btn {
    width: 50%;
}

.dashed-border {
    padding-top: 12px;
    border-bottom: 1px dashed #d6d6d6;
}

.active-btn-2 {
    background-color: #7d96ff1a;
    border: 1px solid #7d96ff;
    font-size: 12px;
    font-weight: 600;
    color: #7d96ff;
    border-radius: 22px;
    padding: 5px 16px;
}
.tab-btn-2 {
    border: 1px solid #00000033;
    border-radius: 22px;
    color: #000000;
    padding: 5px 16px;
}
.gross-sales-category.table-view {
    min-width: calc(100% - 400px);
}

.category-table {
    height: 415px !important;
    border-radius: 16px;
    overflow-y: auto;
}
.total-value {
    width: 100%;
    background-color: #f2f2f2;
}
.total-value td {
    width: 126.67px;
    font-size: 14px;
    padding: 15px 18px;
    font-weight: 600;
    color: #000;
}

.total-value-prod {
    width: 100%;
    background-color: #f2f2f2;
    font-family:
        Noto Sans,
        sans-serif;
}
.total-value td:first-child {
    color: #4169e1;
}

.total-value-prod td {
    width: 100.67px;
    font-size: 14px;
    padding: 15px 18px;
    font-weight: 600;
    color: #000;
}

.total-value-prod td:nth-child(2) {
    color: #4169e1;
}

.sales-dashboard .ag-body-horizontal-scroll-viewport {
    display: none !important;
}

.trend-gross-sales {
    background-color: #fffefb;
    border-radius: 24px;
    border: 1px solid #f2f2f2;
    padding: 32px 24px;
    margin-top: 24px;
}

.brand-wise-sale {
    background-color: #fffefb;
    border-radius: 24px;
    border: 1px solid #f2f2f2;
    padding: 32px 24px;
    margin: 24px 0;
}

.total-value-sale {
    width: 100%;
    background-color: #f2f2f2;
}

.total-value-sale td:first-child {
    color: #4169e1;
}
.total-value-sale td {
    /* width: 86px; */
    font-size: 14px;
    padding: 15px 18px;
    font-weight: 600;
    color: #000;
    max-width: 22px !important;
}

.sales-table {
    height: calc(100vh - 406px);
}

/* .chart-wrapper {
    min-width: 1200px;
    width: max-content;
    height: 400px;
} */

.upload-file .MuiPaper-root.MuiPopover-paper.MuiMenu-paper {
    height: 300px;
}

.uploaded-file-cont {
    padding: 40px 20px;
    border: 1px solid #f2f2f2;
    border-radius: 24px;
    background: #fffefb;
}

.custom-close-btn {
    padding: 17px 64px;
    border-radius: 25px;
}

.ag-theme-alpine .ag-floating-bottom {
    font-weight: 700;
}

.table-cont {
    height: calc(100vh - 330px);
    overflow: auto;
}

.table-header {
    font-weight: bold;
    font-size: 13px;
    display: flex;
    align-items: center;
}
.table-header:first-child,
.table-data:first-child {
    width: 80%;
}
.table-header:last-child,
.table-data:last-child {
    width: 20%;
}

#table-desc {
    padding-left: 18px;
    margin: 8px 0;
}

.custom-height {
    max-height: 400px;
    overflow: auto;
}

.table-row-data {
    max-height: 80px;
    overflow: auto;
    min-height: 80px;
}

.table-row-header {
    background-color: #dae1f9;
    border-radius: 12px 12px 0 0;
    height: 48px;
}

.table-row {
    color: #000;
    gap: 20px;
    padding: 0 18px;
    display: flex;
    width: 100%;
    border-bottom: 1px solid #dde2eb;
}

@media screen and (max-width: 767px) {
    .filter-cont {
        /* flex-direction: column; */
        width: 100%;
        min-width: 0;
        padding-right: 40px !important;
        overflow: auto;
    }
    .fixed-filter {
        top: 113px;
    }
}

@media screen and (max-width: 1024px) {
    .sales-category {
        display: block;
    }
    .gross-sales-category canvas {
        max-height: 200px;
    }
    .gross-sales-category {
        width: 100%;
    }
    .table-view {
        min-width: 0;
        margin-top: 16px;
    }
    .sales-dash-container {
        display: grid;
        grid-template-columns: auto auto;
        gap: 30px;
    }
    .sales-card {
        min-width: 405px;
    }
    .filter-cont {
        /* flex-direction: column; */
        width: 100%;
        min-width: 0;
        padding-right: 185px;
        overflow: auto;
    }
    .first-filter {
        margin-bottom: 0px;
    }
    .chart-wrapper {
        height: auto;
    }
    .gross-sales-category {
        max-width: 100%;
    }
}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
    .sales-dash-container {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 30px;
    }
    .sales-card {
        min-width: 300px;
    }
    .sales-category {
        display: block;
    }
    .gross-sales-category {
        width: 100%;
        margin-bottom: 20px;
        max-width: 100%;
    }
    .category-table {
        height: calc(100vh - 260px) !important;
    }
}

@media screen and (min-width: 1201px) and (max-width: 1600px) {
    .sales-dash-container {
        display: grid;
        grid-template-columns: auto auto auto auto;
        gap: 30px;
    }
    .sales-card {
        min-width: 270px;
    }
    .table-view {
        min-width: 640px;
    }
    .gross-sales-category {
        /* max-width: 610px; */
        min-width: 580px;
    }
    /* .sales-category {
        gap: 22px;
        grid-template-columns: 40% 58%;
    } */
    /* .category-table {
        height: calc(100vh - 452px) !important;
    } */
}
@media screen and (min-width: 1320px) and (max-width: 1400px) {
    /* .table-view {
        min-width: 585px !important;
    } */
    .gross-sales-category {
        /* max-width: 530px; */
        min-width: auto;
    }
    .sales-card {
        min-width: 260px;
    }
    .category-table {
        height: calc(100vh - 452px) !important;
    }
}
@media screen and (max-width: 1390px) {
    .category-table {
        height: calc(100vh - 362px) !important;
    }
}

@media screen and (max-width: 768px) {
    .sales-category {
        display: block;
    }
    .gross-sales-category canvas {
        max-height: 200px;
    }
    .gross-sales-category {
        width: 100%;
        min-width: 0;
    }
    .table-view {
        min-width: 0;
        margin-top: 16px;
    }
    .sales-dash-container {
        /* display: block; */
        gap: 30px;
    }
    .sales-card {
        min-width: 280px;
        margin-bottom: 20px;
    }
    .sales-card:last-child {
        margin-bottom: 0;
    }
    .tab-btn-container {
        min-width: 0;
    }
    .tab-btn,
    .tab-btn-2,
    .active-btn-2 {
        width: 100%;
        padding: 12px 0;
    }
    .btn-cont button:first-child {
        margin-bottom: 12px;
    }
    .total-value-sale td {
        max-width: max-content !important;
    }
}
@media (max-width: 767px) {
    .reportTableList {
        display: block;
    }
    .chart-wrapper {
        height: auto;
    }
    .sales-dash-container {
        display: block;
        gap: 30px;
    }
}
