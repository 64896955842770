/* Mui Filled TextField */
.customTextField .MuiInputBase-root.MuiFilledInput-root {
    background-color: inherit !important;
    font-family: "Noto Sans", sans-serif;
}

.customTextField .MuiInputBase-input.MuiFilledInput-input {
    border: 1px solid #d6d6d6;
    border-radius: 8px;
}

.customTextField .MuiFormLabel-root.MuiInputLabel-root {
    font-family: "Noto Sans", sans-serif;
    color: #676767;
    font-size: 14px;
}

.MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
    color: #676767;
}

.MuiInputBase-root.MuiFilledInput-root::before,
.MuiInputBase-root.MuiFilledInput-root::after,
.customTextField .MuiInputBase-root.MuiFilledInput-root:hover {
    border-bottom: none !important;
}

.customTextField .MuiInputBase-input.MuiFilledInput-input {
    padding-bottom: 6px;
    font-size: 14px;
    font-family: "Noto Sans", sans-serif;
    font-weight: 600;
    background-color: white;
}

.customTextField .MuiFormHelperText-root {
    margin: 0;
}

.customTextField .MuiFormControl-root.MuiTextField-root {
    width: 100%;
}
